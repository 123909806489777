<template>
    <div class="header-wrap">
        <div class="header-content">
            <div class="head-content-left">
                <div class="logo">
                    <img src="../../assets/logo.png" alt="" />
                </div>
                <ul class="list">
                    <li v-for="(item, index) in navList" :key="index" @mouseenter="showDropdown(index)" @mouseleave="leave" :class="item.path === navAct ? 'item item-act' : 'item'">
                        <a @click="skip(item)">{{ item.title }}</a>
                        <div v-if="item.child && activeIndex === index" class="item-box">
                            <div class="triangle-up"></div>
                            <ul class="li-item">
                                <li v-for="(dropdownItem, dropdownIndex) in item.child" :key="dropdownIndex">
                                    <a @click="skip(dropdownItem)">{{ dropdownItem.name }}</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="head-content-right">
                <div class="search">
                    <el-input v-model="input" placeholder="搜索" @focus="inputFocus" @blur="inputBlur"></el-input>
                    <img src="../../assets/search.png" alt="">
                    <div class="searBox">
                        <div class="searbox-title">猜你想搜</div>
                        <div class="searbox-content">
                            <div class="searitem" v-for="(item,index) in searList" :key="index">{{item.value}}</div>
                        </div>
                    </div>
                </div>
                <div class="add">
                    <span>新建</span>
                    <img class="addIcon" src="../../assets/add.png" alt="">
                    <div class="addBox">
                        <div class="triangle-up"></div>
                        <div class="box" @click="addModel">
                            <img src="../../assets/icon_moxing.png" alt="">
                            <span>新建模型</span>
                        </div>
                        <div class="box" @click="addDem">
                            <img src="../../assets/icon_xuqiu.png" alt="">
                            <span>新建需求</span>
                        </div>
                    </div>
                </div>
                <div class="mesg">
                    <img src="../../assets/mes.png" alt="">
                </div>
                <div class="info">
                    <div class="info-des" v-if="$store.getters.token != ''">
                        <img src="../../assets/touxiang.png" alt="">
                        <span>{{$store.getters.user.phone}}</span>
                    </div>
                    <div class="info-des" v-else>
                        <img src="../../assets/Vector@2x.png" alt="">
                        <div class="login" @click="$router.push(`/login`)">请登录</div>
                        <div class="register" @click="$router.push(`/register`)">注册</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Header',
    props: ['topVal'],
    data() {
        return {
            navList:[
                {
                    title:'首页',
                    path:'/home'
                },
                {
                    title:'服务商',
                    path:'/facilitator'
                },
                {
                    title:'我的',
                    path:'/layout'
                },
                {
                    title:'平台服务',
                    path:'/platformServe'
                },
            ],
            navAct: '/home',
            activeIndex: -1,
            input: '',
            searList:[
                {
                    value: '高级3D建模服务'
                },
                {
                    value: '倾斜摄影三维建模服务'
                },
                {
                    value: '3DS Max'
                },
                {
                    value: 'Rhino'
                },
                {
                    value: 'Sketchup'
                },
                {
                    value: '3DS Max'
                },
                {
                    value: '倾斜摄影三维建模服务'
                },
                {
                    value: '游戏人物建模服务'
                },
            ],
            loginState:1,
        }
    },
    methods: {
        // 下拉菜单
        showDropdown(index) {
            if (this.activeIndex === index) {
                this.activeIndex = -1
            } else {
                this.activeIndex = index
            }
        },
        leave() {
            this.activeIndex = -1
        },
        // 跳转
        skip(item) {
            this.navAct = item.path
            this.$router.push({ path: item.path }).catch(err => {})
        },
        inputFocus(){
            document.getElementsByClassName("searBox")[0].style.height = '143px'
            document.getElementsByClassName("searBox")[0].style.width = '397px'
            document.getElementsByClassName("search")[0].style.width = '397px'
        },
        inputBlur(){
            document.getElementsByClassName("searBox")[0].style.height = '0px'
            document.getElementsByClassName("searBox")[0].style.width = '0px'
            document.getElementsByClassName("search")[0].style.width = '140px'
        },
        addDem(){
            // console.log("1");
            this.$store.commit("openAddDemFlag")
        },
        addModel(){
            // console.log("2");
            this.$store.commit("openModelFlag")
        }
    },
    created() {
        this.skip(this.$route)
    },
    watch: {
        $route(to, from) {
            // 监听 $route 对象的变化
            // 可以执行相应的逻辑
            // 例如：根据路由的变化重新加载数据
            // console.log(to,"to");
            this.skip(to)
        },
    },
    
}
</script>

<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
}
.header-wrap {
    height: 1rem;
    background: #000000;
    border: 1px solid #252525;
    transition: all 1.5s ease;
    backdrop-filter: blur(5px);
    width: 24rem;
    z-index: 1000;
    position: absolute;
    .header-content {
        display: flex;
        justify-content: space-between;
        padding: 0rem 3.25rem;
        margin: 0 auto;
        height: 1rem;
        line-height: 1rem;
        font-weight: 400;
        font-size: .25rem;
        color: #FFFFFF;
        .head-content-left {
            display: flex;
            .logo{
                cursor: pointer;
                display: flex;
                align-items: center;
                margin-right: .8rem;
                img{
                    width: 1.95rem;
                    height: .68rem;
                }
            }
            .list {
                display: flex;
                list-style: none;
                .item {
                    .item-box {
                        width: 1.5rem;
                        height: 2.75rem;
                        .li-item{
                            font-weight: 400;
                            font-size: .23rem;
                            color: #B2B2B2;
                        }
                        .triangle-up {
                            position: absolute;
                            left: 40%;
                            top: 1.38rem;
                            width: 0;
                            height: 0;
                            transform: translateX(-50%);
                            -webkit-transform: translateX(-50%);
                            -moz-transform: translateX(-50%);
                            border-left: .13rem solid transparent;  // 根据三角形方向选择对应的boder-direction
                            border-right: .13rem solid transparent; // 根据三角形方向选择对应的boder-direction
                            border-bottom: .13rem solid white; // 根据三角形方向选择对应的boder-direction
                        }
                    }
                    // width: 1.25rem;
                    margin-right: .75rem;
                    height: 0.88rem;
                    position: relative;
                    display: block;
                    a {
                        cursor: pointer;
                    }
                    .li-item {
                        width: 2.38rem;
                        opacity: 1;
                        backdrop-filter: blur(0rem);
                        background-color: #fff;
                        // border-radius: 0.13rem;
                        z-index: 1000;
                        // padding: 0rem 0.13rem;
                        position: absolute;
                        top: 1.5rem;
                        left: -0.75rem;
                        text-align: center;
                        list-style: none;
                        line-height: 0.38rem;
                        font-weight: 400;
                        li {
                            // width: 2.38rem;
                            height: 0.63rem;
                            line-height: 0.63rem;
                            font-size: 0.17rem;
                            font-weight: 400;
                            color: #666666;
                            // box-sizing: content-box;
                        }
                        li:hover {
                            color: #09A966;
                            font-weight: 400;
                        }
                    }
                }
                .item-act {
                    font-weight: 600;
                    font-size: .23rem;
                    color: #FFFFFF;
                }
                .item:last-child{
                    margin-right: 0;
                }
            }
        }
        .head-content-right {
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: .17rem;
            color: #FFFFFF;
            .search{
                transition: all 1s ease;
                position: relative;
                margin-right: .33rem;
                display: flex;
                align-items: center;
                padding: 0rem .19rem 0rem 0rem;
                box-sizing: border-box;
                width: 1.75rem;
                height: .45rem;
                background: #424242;
                img{
                    width: .28rem;
                    height: .28rem;
                }
                .searBox{
                    overflow: hidden;
                    transition: all 1s ease;
                    top: .63rem;
                    position: absolute;
                    width: 4.96rem;
                    height: 0rem;
                    background: #FFFFFF;
                    box-shadow: 0rem .05rem .07rem 0rem rgba(0,0,0,0.03);
                    display: flex;
                    flex-direction: column;
                    padding: 0rem .23rem;
                    box-sizing: border-box;
                    .searbox-title{
                        height: .63rem;
                        line-height: .63rem;
                        font-weight: 400;
                        font-size: .17rem;
                        color: #1D1F23;
                    }
                    .searbox-content{
                        display: flex;
                        flex-wrap: wrap;
                        height: .88rem;
                        overflow: hidden;
                        .searitem{
                            cursor: pointer;
                            padding: 0rem .07rem;
                            line-height: .33rem;
                            height: .33rem;
                            background: #EEEEEE;
                            border-radius: .8rem;
                            font-weight: 400;
                            font-size: .13rem;
                            color: #1D1F23;
                            margin-bottom: .17rem;
                            margin-right: .17rem;
                        }
                    }
                }
                ::v-deep{
                    .el-input__inner{
                        background: #424242 !important;
                        border: none;
                        height: 100%;
                    }
                }
            }
            .add{
                position: relative;
                cursor: pointer;
                margin-right: .33rem;
                display: flex;
                justify-content: space-between;
                padding: 0rem .19rem;
                box-sizing: border-box;
                align-items: center;
                width: 1.13rem;
                height: .45rem;
                background: #424242;
                img{
                    transition: transform 0.5s ease-in-out;
                    width: .28rem;
                    height: .28rem;
                }
                .addBox{
                    // transition: all 0.6s ease;
                    position: absolute;
                    top: .63rem;
                    left: -.19rem;
                    display: flex;
                    flex-direction: column;
                    width: 1.63rem;
                    height: 1.18rem;
                    background: #FFFFFF;
                    box-shadow: .09rem .19rem .55rem 0rem rgba(0,0,0,0.06);
                    visibility:hidden;
                    .box{
                        height: .59rem;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        span{
                            font-weight: 400;
                            font-size: .17rem;
                            color: #1E1E1E;
                        }
                        img{
                            width: .23rem;
                            height: .23rem;
                        }
                    }
                    .triangle-up {
                        position: absolute;
                        left: 50%;
                        top: -.13rem;
                        width: 0;
                        height: 0;
                        transform: translateX(-50%);
                        -webkit-transform: translateX(-50%);
                        -moz-transform: translateX(-50%);
                        border-left: .13rem solid transparent;  // 根据三角形方向选择对应的boder-direction
                        border-right: .13rem solid transparent; // 根据三角形方向选择对应的boder-direction
                        border-bottom: .13rem solid white; // 根据三角形方向选择对应的boder-direction
                    }
                }
            }
            .add:hover{
                .addIcon{
                    transform: rotate(-45deg);
                }
                .addBox{
                    visibility:visible;
                }
            }
            .mesg{
                line-height: normal;
                margin-right: .33rem;
                img{
                    width: .25rem;
                    height: .3rem;
                }
            }
            .info{
                display: flex;
                align-items: center;
                font-weight: 500;
                img{
                    margin-right: .15rem;
                    width: .38rem;
                    height: .38rem;
                }
                .info-des{
                    display: flex;
                    align-items: center;
                    .login{
                        margin-right: 30px;
                        font-weight: 500;
                        font-size: 14px;
                        color: #09A966;
                    }
                    .register{
                        font-weight: 500;
                        font-size: 14px;
                        color: #B2B2B2;
                    }
                }
            }
        }
    }
}
.head-active{
    transition: all 1.5s ease;
    background: #FFFFFF;
    box-shadow: 0rem .06rem .1rem 0rem rgba(0,0,0,0.03);
    .header-content{
        color: #333333;
    }
}

</style>
