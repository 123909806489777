<template>
    <div class="save">
        <div class="automobileMark">
            <div class="title">
                <span>选择产品打印服务商</span>
                <img src="../../assets/wrong.png" alt="" @click="closeAlert">
            </div>
            <div class="choose-box">
                <div class="filtrate">
                    <div class="filtrate-left">
                        <div class="nav">
                            <span>综合</span>
                        </div>
                        <div class="nav" v-for="(item,index) in navList" :key="index" @click="item.val = !item.val">
                            <div class="nav-name">{{item.name}}</div>
                            <img src="../../assets/facilitator/Bot.png" alt="" v-show="item.val">
                            <img src="../../assets/facilitator/Top.png" alt="" v-show="!item.val">
                        </div>
                    </div>
                    <div class="filtrate-right">
                        <div class="sear">
                            <el-input v-model="input" placeholder="搜索" @focus="inputFocus" @blur="inputBlur"></el-input>
                            <img src="../../assets/sear_icon.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="company-content">
                    <div v-for="(company,index) in companyList" :key="company.id" :class="index == comAct ? 'comm-company commAct' : 'comm-company'" @click="comAct = index">
                        <div class="com-left">
                            <img :src="company.image" alt="">
                        </div>
                        <div class="com-right">
                            <div class="name"><span>{{company.name}}</span><span class="border">|</span><span v-for="(item,index) in company.label" :key="index" class="label">{{item}}</span></div>
                            <div class="good">
                                <img src="../../assets/detail/good.png" alt="" v-for="(item,index) in company.level" :key="index">
                                <img src="../../assets/detail/bad.png" alt="" v-for="(item,index) in company.leve" :key="index">
                                <span class="good-num">{{company.level}}.0</span>|<span>{{company.comment_count}}人评论</span>
                            </div>
                            <div class="site">
                                <img src="../../assets/detail/site.png" alt="">
                                <span>{{company.address}}</span>|<span>距离我{{company.distance}}</span>
                            </div>
                            <div class="workDate">
                                <img src="../../assets/detail/date.png" alt="">
                                <span>{{company.business_hours}}</span>
                            </div>
                        </div>
                        <img class="checked" src="../../assets/dentistry/checked.png" alt="" v-show="index == comAct">
                    </div>
                </div>
                <div class="main-pages">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :prev-text="'上一页'"
                        :next-text="'下一页'"
                        :page-size="limit" 
                        @current-change="handlePageChange"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
            <div class="bottom-btn">
                <div class="cancel">取消</div>
                <div class="submit" @click="submit">确认选择</div>
            </div>
        </div>
    </div>
</template>

<script>
import { getFac } from "../../api/facilitator.js"
    export default {
        data(){
            return{
                rayFlag: true,
                value: '',
                input: "",
                navList:[
                    {
                        name:"商户规模",
                        val: true
                    },
                    {
                        name:"客户评价",
                        val: false
                    },
                    {
                        name:"距离",
                        val: false
                    },
                    {
                        name:"区域",
                        val: false
                    },
                    {
                        name:"筛选",
                        val: false
                    },
                ],
                companyList:[
                    {
                        id:1,
                        img:require("../../assets/touxiang.png"),
                        label:["3D建模","产品打印"],
                        name:"东莞市科品快速成型技术有限公司",
                        good:4,
                        comment:230,
                        workDate:"工作日 08:30-18:00",
                        site:"广东省 东莞市 成华大道 二仙桥",
                        isLike:0,
                        distance:"100km",
                    },
                    {
                        id:2,
                        img:require("../../assets/touxiang.png"),
                        label:["3D建模","产品打印"],
                        name:"东莞市科品快速成型技术有限公司",
                        good:4,
                        comment:230,
                        workDate:"工作日 08:30-18:00",
                        site:"广东省 东莞市 成华大道 二仙桥",
                        isLike:0,
                        distance:"100km",
                    },
                    {
                        id:3,
                        img:require("../../assets/touxiang.png"),
                        label:["3D建模","产品打印"],
                        name:"东莞市科品快速成型技术有限公司",
                        good:4,
                        comment:230,
                        workDate:"工作日 08:30-18:00",
                        site:"广东省 东莞市 成华大道 二仙桥",
                        isLike:0,
                        distance:"100km",
                    },
                    {
                        id:4,
                        img:require("../../assets/touxiang.png"),
                        label:["3D建模","产品打印"],
                        name:"东莞市科品快速成型技术有限公司",
                        good:4,
                        comment:230,
                        workDate:"工作日 08:30-18:00",
                        site:"广东省 东莞市 成华大道 二仙桥",
                        isLike:0,
                        distance:"100km",
                    },
                    {
                        id:5,
                        img:require("../../assets/touxiang.png"),
                        label:["3D建模","产品打印"],
                        name:"东莞市科品快速成型技术有限公司",
                        good:4,
                        comment:230,
                        workDate:"工作日 08:30-18:00",
                        site:"广东省 东莞市 成华大道 二仙桥",
                        isLike:0,
                        distance:"100km",
                    },
                ],
                comAct:0,
                page: 1,
                limit: 5,
                total:0,
            }
        },
        methods:{
            submit(){
                // console.log(this.companyList[this.comAct]);
                this.$store.commit('setItem',this.companyList[this.comAct])
                this.closeAlert()
                this.$store.commit('openAddDemFlag')
            },
            closeAlert() {
                this.$store.commit('closeChooseDemFlag')
            },
            inputFocus(){
                document.getElementsByClassName("sear")[0].style.width = '397px'
            },
            inputBlur(){
                document.getElementsByClassName("sear")[0].style.width = '140px'
            },
            //切换页码
            handlePageChange(e) {
                // console.log(e,"页数")
                this.page = e
                this.getData()
            },
            // 获取信息
            getData(){
                getFac({
                    lat:this.$store.getters.lat,
                    lon:this.$store.getters.lon,
                    sort: "distance",
                    order: "asc",
                    limit: this.limit,
                    page: this.page,
                }).then(res=>{
                    console.log(res);
                    this.total = res.count
                    this.companyList = res.data
                    this.companyList.forEach(item=>{
                        item.level = Number(item.level.slice(0,1))
                        item.leve = 5 - item.level
                    })
                }).catch(err=>{

                })
            }
        },
        mounted(){
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
.save{
    z-index: 1999;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    .automobileMark{
        box-sizing: border-box;
        position: fixed;
        top: 1.31rem;
        left: 3.25rem;
        height: 7.5rem;
        overflow-x: auto;
        padding: 0rem 1.5rem;
        box-sizing: border-box;
        background: linear-gradient( 180deg, #F6FFFB 0%, #FFFFFF 100%);
        .title{
            margin: .75rem 0rem .56rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            span{
                font-weight: 500;
                font-size: .25rem;
                color: #1D1F23;
            }
            img{
                cursor: pointer;
                width: .35rem;
                height: .35rem;
            }
        }
        .choose-box{
            width: 14.5rem;
            .filtrate{
                background: pink;
                width: 100%;
                height: 1.02rem;
                background: #FFFFFF;
                // padding: 0px 260px;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                .filtrate-left{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: .19rem;
                    color: #1D1F23;
                    .nav{
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        margin-right: .38rem;
                        .nav-name{
                            color: #09A966;
                            margin-right: .07rem;
                        }
                        img{
                            width: .1rem;
                            height: .14rem;
                        }
                    }
                }
                .filtrate-right{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: .19rem;
                    color: #788086;
                    .sear{
                        transition: all 1s ease;
                        position: relative;
                        margin-right: .33rem;
                        display: flex;
                        align-items: center;
                        padding: 0rem .19rem 0rem 0rem;
                        box-sizing: border-box;
                        width: 1.75rem;
                        height: .45rem;
                        border: 1px solid #8594A2;
                        // background: #424242;
                        img{
                            width: .28rem;
                            height: .28rem;
                        }
                        ::v-deep{
                            .el-input__inner{
                                // background: #424242 !important;
                                border: none;
                                height: 100%;
                            }
                        }
                    }
                }
            }
            .company-content{
                .comm-company{
                    margin-bottom: 20px;
                    position: relative;
                    display: flex;
                    width: 100%;
                    height: 150px;
                    padding: 21px 0px;
                    box-sizing: border-box;
                    border: 1px solid #C4C6CF;
                    .com-left{
                        // margin: .3rem;
                        padding: 0px 20px 0px 10px;
                        box-sizing: border-box;
                        img{
                            width: 1.35rem;
                            height: 1.35rem;
                        }
                    }
                    .com-right{
                        display: flex;
                        flex-direction: column;
                        .name{
                            display: flex;
                            :nth-child(1){
                                font-weight: 500;
                                font-size: .2rem;
                                color: #1D1F23;
                            }
                            .border{
                                margin: 0rem .13rem;
                                color: #D7DBE6;
                            }
                            .label{
                                margin-right: .06rem;
                                display: block;
                                font-weight: 400;
                                font-size: .13rem;
                                color: #09A966;
                                background: #EDFBF5;
                                padding: .03rem .09rem;
                                box-sizing: border-box;
                            }
                        }
                        .good{
                            margin: .06rem 0rem .19rem;
                            display: flex;
                            align-items: center;
                            height: .25rem;
                            img{
                                width: .17rem;
                                height: .17rem;
                            }
                            .good-num{
                                margin: 0rem .06rem;
                            }
                            :last-child{
                                font-weight: 400;
                                font-size: .15rem;
                                color: #8594A2;
                                margin-left: .06rem;
                            }
                        }
                        .site{
                            height: .25rem;
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                            font-size: .15rem;
                            color: #8594A2;
                            img{
                                width: .23rem;
                                height: .23rem;
                            }
                            :nth-child(2){
                                margin: 0rem .06rem;
                            }
                            :last-child{
                                margin-left: .06rem;
                            }
                        }
                        .workDate{
                            margin: .1rem 0rem .24rem;
                            height: .25rem;
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                            font-size: .15rem;
                            color: #8594A2;
                            img{
                                width: .23rem;
                                height: .23rem;
                            }
                        }
                    }
                    .checked{
                        position: absolute;
                        right: 0rem;
                        top: 0rem;
                        width: .38rem;
                        height: .38rem;
                    }
                }
                .commAct{
                    background: linear-gradient( 180deg, #F6FFFB 0%, #FFFFFF 100%);
                    border: 1px solid #09A966;
                }
            }
            .main-pages{
                display: flex;
                justify-content: center;
                margin-top: .44rem;
                ::v-deep{
                    .btn-prev,.btn-next{
                        padding: .09rem .26rem;
                        border: .01rem solid #D9D9D9;
                        span{
                            line-height: .19rem !important;
                        }
                    }
                    .el-pager li{
                        border: .01rem solid #D9D9D9;
                    }
                    .el-pagination.is-background .el-pager li:not(.disabled).active{
                        background: #1D1F23;
                        font-weight: 400;
                        font-size: .17rem;
                        color: #FFFFFF;
                    }
                    .el-icon-more{
                        border: none !important;
                    }
                }
            }
        }
        .bottom-btn{
            margin: .63rem 0rem .63rem;
            display: flex;
            justify-content: flex-end;
            .cancel,.submit{
                width: .85rem;
                height: .5rem;
                line-height: .5rem;
                text-align: center;
                font-weight: 500;
                font-size: .17rem;
            }
            .cancel{
                background: #FFFFFF;
                color: #09A966;
                border: .01rem solid #09A966;
            }
            .submit{
                margin-left: .25rem;
                background: #09A966;
                color: #FFFFFF;
                border: .01rem solid #09A966;
            }
        }
    }
}
</style>