<template>
    <div class="dentistryOne">
        <div class="way">
            <div class="way-title">诊断方式</div>
            <div class="way-content">
                <div class="item" @click="wayAct=0">
                    <img src="../../assets/component/check.png" alt="" v-if="wayAct==0">
                    <img src="../../assets/component/discheck.png" alt="" v-else>
                    <span>线上诊断</span>
                </div>
                <div class="item" @click="wayAct=1">
                    <img src="../../assets/component/check.png" alt="" v-if="wayAct==1">
                    <img src="../../assets/component/discheck.png" alt="" v-else>
                    <span>线下诊断</span>
                </div>
            </div>
        </div>
        <div class="patientInfo">
            <div class="patient-top">
                <div class="patient-top-left">
                    <span>患者姓名</span>
                    <el-input placeholder="请输入患者姓名" v-model="name" clearable></el-input>
                </div>
                <div class="patient-top-right">
                    <span>患者手机号</span>
                    <el-input placeholder="请输入患者电话号码" v-model="phone" clearable></el-input>
                </div>
            </div>
            <div class="patient-bot">
                <span>患者病史</span>
                <el-input type="textarea" :rows="2" placeholder="请输入患者病史" v-model="medical"></el-input>
            </div>
        </div>
        <div class="ray">
            <div class="ray-title">
                <span>牙齿X光片</span>
                <el-switch v-model="rayFlag" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                <span>请上传JPG、JPEG、PNG、PDF、WOD格式文件</span>
            </div>
            <div class="ray-box" v-if="rayFlag">
                <el-upload
                class="upload-demo"
                action=""
                :file-list="fileList"
                :http-request="uploadFile"
                :show-file-list="true"
                list-type="picture"
                multiple>
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
            </div>
        </div>
        <div class="ray">
            <div class="ray-title">
                <span>牙齿X光片</span>
                <el-switch v-model="RayFlag" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                <span>请上传JPG、JPEG、PNG、PDF、WOD格式文件</span>
            </div>
            <div class="ray-box" v-if="RayFlag">

            </div>
        </div>
        <div class="examine">
            <div class="examine-title">
                <span>在线检查</span>
                <el-switch v-model="examine" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </div>
            <div class="examine-box" v-if="examine">
                <!-- <div class="examine-box-left">
                    <div class="examine-left-title">
                        <span>匹配医生</span>
                        <img src="../../assets/wrong.png" alt="">
                    </div>
                    <div class="img">
                        <img :src="doctorInfo.img" alt="">
                    </div>
                    <div class="size">{{doctorInfo.post}}<span class="border">|</span><span>{{doctorInfo.date}}</span></div>
                    <div class="name">{{doctorInfo.name}}</div>
                    <div class="btn">立即接通</div>
                </div>
                <div class="examine-box-right">
                    <div class="examine-right-title">医生诊断建议</div>
                    <div class="examine-right-box"></div>
                </div> -->
            </div>
        </div>
        <div class="bottom-btn">
            <div class="cancel">取消</div>
            <div class="submit" @click="denAdd">提交</div>
        </div>
    </div>
</template>

<script>
import { cosUpload } from '../../utils/cosUtils';
    export default {
        name: 'DentistryOne',
        data() {
            return {
                wayAct:0,
                phone:'',
                name:'',
                medical:"",
                rayFlag: true,
                RayFlag: true,
                examine: true,
                doctorInfo:{
                    img:require("../../assets/dentistry/listOne.png"),
                    date:"10年临床经验",
                    post:"辉煌牙科医院主治大夫",
                    name:"安宏"
                },
                fileList: []
            }
        },
        methods: {
            denAdd(){
                this.$emit('addAct')
            },
            // 上传文件
            async uploadFile(item) {
                const fileUrl = await cosUpload(item.file);
                // console.log(item,"文件上传");
                // this.img.img = fileUrl;
                // this.img.name = item.file.name;
                console.log(fileUrl,"文件上传");
                let size = item.file.size / 1024 / 1024
                this.img.size = size.toFixed(2)
                this.fileList.push({
                    img:fileUrl,
                    name:item.file.name,
                    size:size.toFixed(2),
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
.dentistryOne{
    .way{
        width: 3.88rem;
        height: .68rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .way-title{
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
        }
        .way-content{
            display: flex;
            .item{
                cursor: pointer;
                margin-right: .63rem;
                display: flex;
                align-items: center;
                img{
                    width: .2rem;
                    height: .2rem;
                    margin-right: .07rem;
                }
                span{
                    font-weight: 400;
                    font-size: .15rem;
                    color: #1D1F23;
                }
            }
        }
    }
    .patientInfo{
        margin-top: .38rem;
        .patient-top{
            width: 100%;
            display: flex;
            justify-content: space-between;
            .patient-top-left,.patient-top-right{
                display: flex;
                flex-direction: column;
                span{
                    margin-bottom: .13rem;
                    font-weight: 600;
                    font-size: .15rem;
                    color: #1D1F23;
                }
                ::v-deep{
                    .el-input__inner{
                        width: 6.63rem !important;
                        border-radius: 0rem;
                    }
                }
            }
        }
        .patient-bot{
            margin-top: .38rem;
            display: flex;
            flex-direction: column;
            span{
                margin-bottom: .14rem;
                font-weight: 600;
                font-size: .15rem;
                color: #1D1F23;
            }
            ::v-deep{
                .el-textarea__inner{
                    height: 1.4rem;
                }
            }
        }
    }
    .ray{
        margin-top: .38rem;
        .ray-title{
            margin-bottom: .15rem;
            :nth-child(1){
                font-weight: 600;
                font-size: .15rem;
                color: #1D1F23;
                margin-right: .1rem;
            }
            :last-child{
                margin-left: .13rem;
                font-weight: 400;
                font-size: .13rem;
                color: #8594A2;
            }
        }
        .ray-box{
            width: 14.5rem;
            height: 2.77rem;
            border-radius: 0rem 0rem 0rem 0rem;
            border: .01rem solid #D7DBE6;
            .upload{
                width: 2.13rem;
                height: 2.13rem;
                background: url("../../assets/dentistry/upload-bg.png");
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: .6rem;
                    height: .6rem;
                }
            }
        }
    }
    .examine{
        margin-top: .38rem;
        .examine-title{
            margin-bottom: .15rem;
            :nth-child(1){
                font-weight: 600;
                font-size: .15rem;
                color: #1D1F23;
                margin-right: .1rem;
            }
        }
        .examine-box{
            width: 14.5rem;
            height: 6.08rem;
            border-radius: 0rem 0rem 0rem 0rem;
            border: .01rem solid #D7DBE6;
            display: flex;
            .examine-box-left{
                margin:.25rem .5rem 0rem .25rem;
                width: 3.25rem;
                display: flex;
                flex-direction: column;
                .examine-left-title{
                    height: .25rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span{
                        font-weight: 400;
                        font-size: .15rem;
                        color: #1D1F23;
                    }
                    img{
                        width: .15rem;
                        height: .15rem;
                    }
                }
                .img{
                    margin-top: .13rem;
                    width: 3.25rem;
                    height: 3.75rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .size{
                    .border{
                        margin: 0rem .06rem;
                    }
                    :last-child{
                        color: rgba(9, 169, 102, 1);
                    }
                    height: .46rem;
                    line-height: .46rem;
                    font-weight: 400;
                    font-size: .15rem;
                    color: #8594A2;
                    border-bottom: .01rem solid #F5F5F5;
                }
                .name{
                    margin-top: .1rem;
                    font-weight: 600;
                    font-size: .2rem;
                    color: #1D1F23;
                }
                .btn{
                    margin-left: calc(50% - .61rem);
                    margin-top: .28rem;
                    width: 1.23rem;
                    height: .4rem;
                    line-height: .4rem;
                    text-align: center;
                    background: #09A966;
                    font-weight: 400;
                    font-size: .17rem;
                    color: #FFFFFF;
                }
            }
            .examine-box-right{
                width: 10.13rem;
                .examine-right-title{
                    font-weight: 400;
                    font-size: .15rem;
                    color: #1D1F23;
                    height: .25rem;
                    margin: .25rem 0rem .13rem;
                }
                .examine-right-box{
                    width: 10.13rem;
                    height: 5.24rem;
                    border-radius: 0rem 0rem 0rem 0rem;
                    border: .01rem solid #D7DBE6;
                }
            }
        }
    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        .cancel,.submit{
            width: .85rem;
            height: .5rem;
            line-height: .5rem;
            text-align: center;
            font-weight: 500;
            font-size: .17rem;
        }
        .cancel{
            background: #FFFFFF;
            color: #09A966;
            border: .01rem solid #09A966;
        }
        .submit{
            margin-left: .25rem;
            background: #09A966;
            color: #FFFFFF;
            border: .01rem solid #09A966;
        }
    }
}
</style>