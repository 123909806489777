<template>
    <div class="footer-wrap">
        邯郸工业设计中心有限公司
    </div>
</template>

<script>
export default {
    name: 'Footer',
    mounted() {
    },
    data() {
        return {

        }
    },
    methods: {
        skip(val) {
            this.$router.push(`/${val}`).catch(err => {})
        },
        open(){
            window.open('https://beian.miit.gov.cn/', '_blank')
        }
    },
}
</script>

<style lang="scss" scoped>
* {
    padding: 0;
    margin: 0;
}
.footer-wrap {
    height: 1rem;
    background: #1D1F23;
    font-weight: 400;
    font-size: .17rem;
    color: #FFFFFF;
    text-align: center;
    line-height: 1rem;
}
</style>
