<template>
    <div class="automobileTwo">
        <div class="model-title">
            <span>需求名称</span>
        </div>
        <div class="auto-box">
            <div class="model">
                <div class="left">
                    <div class="model-left">
                        <img :src="model.img" alt="">
                    </div>
                    <div class="model-right">
                        <div class="model-right-top">{{model.name}}</div>
                        <div class="model-right-bot">
                            <span class="title">长宽高：</span><span class="val">{{model.size}}</span>
                            <span class="title">体积：</span><span class="val">{{model.volume}}</span>
                            <span class="title">表面积：</span><span class="val">{{model.area}}</span>
                        </div>
                    </div>
                </div>
                <div class="border"></div>
                <div class="right">
                    <div class="right-top">
                        <span v-for="(item,index) in model.label" :key="index">{{item}}</span>
                    </div>
                    <div class="right-bot">
                        <span class="title">模具分类：</span><span class="val">产品设计</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-btn">
            <div class="submit" @click="autoAdd">下一步</div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                model:{
                    img:require("../../assets/detail/mater-one.png"),
                    name:"患者-牙齿模型.stl",
                    size:"24x8x2mm",
                    volume:"384mm²",
                    area:"500mm³",
                    label:["艺术品","毕业设计","3D打印"]
                },
            }
        },
        methods:{
            autoAdd(){
                this.$emit('addAct')
            }
        }
    }
</script>

<style lang="scss" scoped>
.automobileTwo{
    .model-title{
        margin: .63rem 0rem .13rem;
        :nth-child(1){
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-right: .1rem;
        }
    }
    .auto-box{
        width: 14.5rem;
        height: 10.63rem;
        border: .01rem solid #D7DBE6;
        padding: .25rem;
        box-sizing: border-box;
        .model{
            display: flex;
            align-items: center;
            height: 1.4rem;
            background: #F8FAFB;
            border: .01rem solid #F8FAFB;
            .left{
                width: 7.25rem;
                display: flex;
                .model-left{
                    img{
                        margin: .13rem .23rem;
                        width: 1.15rem;
                        height: 1.15rem;
                    }
                }
                .model-right{
                    .model-right-top{
                        font-weight: 500;
                        font-size: .17rem;
                        color: #1D1F23;
                        margin: .4rem 0rem .17rem;
                    }
                    .model-right-bot{
                        font-weight: 300;
                        font-size: .13rem;
                        color: #788086;
                        .val{
                            margin-right: .13rem;
                        }
                    }
                }
            }
            .border{
                width: 0rem;
                height: .69rem;
                border: .01rem solid #D7DBE6;
            }
            .right{
                padding-left: .63rem;
                .right-top{
                    display: flex;
                    margin-bottom: .17rem;
                    span{
                        display: block;
                        font-weight: 400;
                        font-size: .13rem;
                        color: #09A966;
                        padding: .03rem .09rem;
                        background: #EDFBF5;
                        margin-right: .06rem;
                    }
                }
                .right-bot{
                    .title{
                        font-weight: 300;
                        font-size: .13rem;
                        color: #8594A2;
                    }
                    .val{
                        font-weight: 500;
                        font-size: .13rem;
                        color: #1D1F23;
                    }
                }
            }
        }

    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        .cancel,.submit{
            width: .85rem;
            height: .5rem;
            line-height: .5rem;
            text-align: center;
            font-weight: 500;
            font-size: .17rem;
        }
        .cancel{
            background: #FFFFFF;
            color: #09A966;
            border: .01rem solid #09A966;
        }
        .submit{
            margin-left: .25rem;
            background: #09A966;
            color: #FFFFFF;
            border: .01rem solid #09A966;
        }
    }
}
</style>