<template>
    <div class="save">
        <div class="automobileMark">
            <div class="title">
                <span>选择服务</span>
                <img src="../../assets/wrong.png" alt="" @click="closeAlert">
            </div>
            <div class="save-content">
                <div class="ray">
                    <div class="ray-title">
                        <span>3D建模服务（不可选）</span>
                    </div>
                    <div class="ray-box">
                        <el-select v-model="value" disabled placeholder="选择3D建模服务商">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="ray-two-title">
                    <span>产品打印服务</span>
                </div>
                <div class="ray-two">
                    <div class="ray-service-item">
                        <div class="company-title">
                            <div class="company-title-left">
                                <span @click="serviceOne = !serviceOne">{{serviceOne ? '开' : '关'}}</span>
                                <span>选择产品打印服务商</span>
                                <span>根据您的偏好数据，为您推荐以下服务商</span>
                            </div>   
                            <div class="company-title-right">
                                <div class="bargain" @click="cupServeice">
                                    <img src="../../assets/index/refresh.png" alt="">
                                    <span>换一个</span>
                                </div>
                                <div class="check" @click="goSelect">
                                    <img src="../../assets/index/check.png" alt="">
                                    <span>去选择</span>
                                </div>
                            </div>
                        </div>
                        <div class="comm-company" v-show="serviceOne">
                            <div class="com-left">
                                <img :src="companyProOne.image" alt="">
                            </div>
                            <div class="com-right">
                                <div class="name"><span>{{companyProOne.name}}</span><span class="border">|</span><span v-for="(item,index) in companyProOne.label" :key="index" class="label">{{item}}</span></div>
                                <div class="good">
                                    <img src="../../assets/detail/good.png" alt="" v-for="(item,index) in companyProOne.level" :key="index">
                                    <img src="../../assets/detail/bad.png" alt="" v-for="(item,index) in companyProOne.leve" :key="index">
                                    <span class="good-num">{{companyProOne.level}}.0</span>|<span>{{companyProOne.comment_count}}人评论</span>
                                </div>
                                <div class="site">
                                    <img src="../../assets/detail/site.png" alt="">
                                    <span>{{companyProOne.address}}</span>|<span>距离我{{companyProOne.distance}}</span> 
                                </div>
                                <div class="workDate">
                                    <img src="../../assets/detail/date.png" alt="">
                                    <span>{{companyProOne.business_hours}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="ray-service-item">
                        <div class="company-title">
                            <div class="company-title-left">
                                <span @click="serviceTwo = !serviceTwo">{{serviceTwo ? '开' : '关'}}</span>
                                <span>选择打印耗材服务商</span>
                                <span>根据您的偏好数据，为您推荐以下服务商</span>
                            </div>   
                            <div class="company-title-right">
                                <div class="bargain">
                                    <img src="../../assets/index/refresh.png" alt="">
                                    <span>换一个</span>
                                </div>
                                <div class="check">
                                    <img src="../../assets/index/check.png" alt="">
                                    <span>去选择</span>
                                </div>
                            </div>
                        </div>
                        <div class="comm-company" v-show="serviceTwo">
                            <div class="com-left">
                                <img :src="companyProTwo.image" alt="">
                            </div>
                            <div class="com-right">
                                <div class="name"><span>{{companyProTwo.name}}</span><span class="border">|</span><span v-for="(item,index) in companyProTwo.label" :key="index" class="label">{{item}}</span></div>
                                <div class="good">
                                    <img src="../../assets/detail/good.png" alt="" v-for="(item,index) in companyProTwo.level" :key="index">
                                    <img src="../../assets/detail/bad.png" alt="" v-for="(item,index) in companyProTwo.leve" :key="index">
                                    <span class="good-num">{{companyProTwo.level}}.0</span>|<span>{{companyProTwo.comment_count}}人评论</span>
                                </div>
                                <div class="site">
                                    <img src="../../assets/detail/site.png" alt="">
                                    <span>{{companyProTwo.address}}</span>|<span>距离我{{companyProTwo.distance}}</span> 
                                </div>
                                <div class="workDate">
                                    <img src="../../assets/detail/date.png" alt="">
                                    <span>{{companyProTwo.business_hours}}</span>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
                <!-- <div class="ray-two-title">
                    <span>是否选择产品测试服务</span>
                    <el-switch v-model="rayFlag" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                </div>
                <div class="ray-three" v-if="rayFlag">
                    <div class="ray-service-item">
                        <div class="company-title">
                            <div class="company-title-left">
                                <span @click="serviceOne = !serviceOne">{{serviceOne ? '开' : '关'}}</span>
                                <span>打印耗材服务商</span>
                                <span>根据您的偏好数据，为您推荐以下服务商</span>
                            </div>   
                            <div class="company-title-right">
                                <div class="bargain">
                                    <img src="../../assets/index/refresh.png" alt="">
                                    <span>换一个</span>
                                </div>
                                <div class="check">
                                    <img src="../../assets/index/check.png" alt="">
                                    <span>去选择</span>
                                </div>
                            </div>
                        </div>
                        <div class="comm-company" v-show="serviceOne">
                            <div class="com-left">
                                <img :src="companyTest.image" alt="">
                            </div>
                            <div class="com-right">
                                <div class="name"><span>{{companyTest.name}}</span><span class="border">|</span><span v-for="(item,index) in companyTest.label" :key="index" class="label">{{item}}</span></div>
                                <div class="good">
                                    <img src="../../assets/detail/good.png" alt="" v-for="(item,index) in companyTest.level" :key="index">
                                    <img src="../../assets/detail/bad.png" alt="" v-for="(item,index) in companyTest.leve" :key="index">
                                    <span class="good-num">{{companyTest.level}}.0</span>|<span>{{companyTest.comment_count}}人评论</span>
                                </div>
                                <div class="site">
                                    <img src="../../assets/detail/site.png" alt="">
                                    <span>{{companyTest.address}}</span>|<span>距离我{{companyTest.distance}}</span> 
                                </div>
                                <div class="workDate">
                                    <img src="../../assets/detail/date.png" alt="">
                                    <span>{{companyTest.business_hours}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="ray">
                    <div class="ray-title">
                        <span>3D建模服务（不可选）</span>
                    </div>
                    <div class="ray-box">
                        <el-select v-model="value" disabled placeholder="选择3D建模服务商">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="bottom-btn">
                    <div class="submit" @click="denAdd">提需求</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getFac } from "../../api/facilitator.js"
    export default {
        data(){
            return{
                rayFlag: true,
                value: '',
                serviceOne: true,
                serviceTwo: true,
                options: [
                    {
                        value: '选项1',
                        label: '黄金糕'
                    },
                    {
                        value: '选项2',
                        label: '双皮奶'
                    },
                ],
                company:{
                    img:require("../../assets/touxiang.png"),
                    label:["3D建模","产品打印"],
                    name:"东莞市科品快速成型技术有限公司",
                    good:4,
                    comment:230,
                    workDate:"工作日 08:30-18:00",
                    site:"广东省 东莞市 成华大道 二仙桥",
                    isLike:0,
                    distance:"100km",
                },
                page: 1,
                limit: 5,
                total:0,
                companyProOne:{},
                companyProTwo:{},
                companyTest:{},
                serviceList:[]
            }
        },
        methods:{
            // 切换服务商
            cupServeice(){
                this.companyProOne = this.serviceList[Math.floor(Math.random()*5)]
                // console.log(Math.floor(Math.random()*5))
            },
            closeAlert() {
                this.$store.commit('closeAddDemFlag')
            },
            denAdd(){
                this.$store.commit("setDem",{title:'is_fix',val:2})
                this.$store.commit("setMat",this.companyProOne.material)
                this.$store.commit("setPro",this.companyProOne.product)
                this.$store.commit("setShopId",this.companyProOne.id)
                this.closeAlert()
                this.$store.commit('openDemFlag')
            },
            goSelect(){
                this.closeAlert()
                this.$store.commit("openChooseDemFlag")
            },
            // 获取信息
            getData(){
                getFac({
                    lat:this.$store.getters.lat,
                    lon:this.$store.getters.lon,
                    sort: "distance",
                    order: "asc",
                    limit: this.limit,
                    page: this.page,
                }).then(res=>{
                    console.log(res);
                    res.data.forEach(item=>{
                        item.level = Number(item.level.slice(0,1))
                        item.leve = 5 - item.level
                    })

                    this.serviceList = res.data

                    if(JSON.stringify(this.$store.getters.serviceItem) == "{}"){
                        console.log("1111");
                        this.companyProOne = res.data[0]
                    }else{
                        this.companyProOne = this.$store.getters.serviceItem
                        console.log("2222",this.companyProOne);
                    }
                    this.companyProTwo = res.data[1]
                    this.companyTest = res.data[0]
                    // this.total = res.count
                    // this.mainList = res.data
                }).catch(err=>{

                })
            }
        },
        mounted(){
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
.save{
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    .automobileMark{
        box-sizing: border-box;
        position: fixed;
        top: 1.31rem;
        left: 3.25rem;
        height: 7.5rem;
        overflow-x: auto;
        padding: 0rem 1.5rem;
        box-sizing: border-box;
        background: linear-gradient( 180deg, #F6FFFB 0%, #FFFFFF 100%);
        .title{
            margin: .75rem 0rem .56rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            span{
                font-weight: 500;
                font-size: .25rem;
                color: #1D1F23;
            }
            img{
                cursor: pointer;
                width: .35rem;
                height: .35rem;
            }
        }
        .save-content{
            width: 14.5rem;
            .ray{
                margin-top: .38rem;
                .ray-title{
                    margin-bottom: .15rem;
                    font-weight: 600;
                    font-size: .15rem;
                    color: #1D1F23;
                    margin-right: .1rem;
                }
                .ray-box{
                    width: 14.5rem;
                    height: 1.26rem;
                    border: .01rem solid #D7DBE6;
                    padding: .25rem;
                    box-sizing: border-box;
                    ::v-deep{
                        .el-input__inner{
                            width: 14rem;
                            height: .76rem;
                            background: #F8FAFB;
                        }
                    }
                }
            }
            .ray-two-title{
                margin: .38rem 0rem .13rem;
                font-weight: 600;
                font-size: .15rem;
                color: #1D1F23;
                // margin-bottom: .15rem;
                // :nth-child(1){
                //     font-weight: 600;
                //     font-size: .15rem;
                //     color: #1D1F23;
                //     margin-right: .1rem;
                // }
                // :last-child{
                //     margin-left: .13rem;
                //     font-weight: 400;
                //     font-size: .13rem;
                //     color: #8594A2;
                // }
            }
            .ray-two{
                // margin-top: .38rem;
                width: 14.5rem;
                // height: 484px;
                border: .01rem solid #D7DBE6;
                display: flex;
                flex-direction: column;
                // justify-content: space-between;
                padding: .25rem .25rem 0rem .25rem;
                box-sizing: border-box;
                .ray-service-item{
                    width: 14rem;
                    // height: 212px;
                    background: #F8FAFB;
                    margin-bottom: .25rem;
                    .company-title {
                        padding: 0rem .25rem;
                        box-sizing: border-box;
                        // margin: .47rem 0rem .21rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: .75rem;
                        border-bottom: .01rem solid #D7DBE6;
                        .company-title-left{
                            font-weight: 600;
                            font-size: .15rem;
                            color: #1D1F23;
                            :nth-child(1){
                                margin-right: .13rem;
                            }
                            :last-child{
                                margin-left: .13rem;
                                font-weight: 400;
                                font-size: .13rem;
                                color: #09A966;
                            }
                        }
                        .company-title-right{
                            display: flex;
                            .bargain,.check{
                                justify-content: space-around;
                                align-items: center;
                                display: flex;
                                padding: .07rem .1rem;
                                box-sizing: border-box;
                                border: .01rem solid #09A966;
                                font-weight: 500;
                                font-size: .17rem;
                                color: #09A966;
                                img{
                                    margin-right: .07rem;
                                    width: .25rem;
                                    height: .25rem;
                                }
                            }
                            .bargain{
                                margin-right: .25rem;
                            }
                        }
                    }
                    .comm-company{
                        position: relative;
                        display: flex;
                        width: 100%;
                        height: 1.88rem;
                        padding: .26rem 0rem;
                        box-sizing: border-box;
                        .com-left{
                            // margin: .3rem;
                            padding: 0rem .25rem 0rem .13rem;
                            box-sizing: border-box;
                            img{
                                width: 1.35rem;
                                height: 1.35rem;
                            }
                        }
                        .com-right{
                            display: flex;
                            flex-direction: column;
                            .name{
                                display: flex;
                                :nth-child(1){
                                    font-weight: 500;
                                    font-size: .2rem;
                                    color: #1D1F23;
                                }
                                .border{
                                    margin: 0rem .13rem;
                                    color: #D7DBE6;
                                }
                                .label{
                                    margin-right: .06rem;
                                    display: block;
                                    font-weight: 400;
                                    font-size: .13rem;
                                    color: #09A966;
                                    background: #EDFBF5;
                                    padding: .03rem .09rem;
                                    box-sizing: border-box;
                                }
                            }
                            .good{
                                margin: .06rem 0rem .19rem;
                                display: flex;
                                align-items: center;
                                height: .25rem;
                                img{
                                    width: .17rem;
                                    height: .17rem;
                                }
                                .good-num{
                                    margin: 0rem .06rem;
                                }
                                :last-child{
                                    font-weight: 400;
                                    font-size: .15rem;
                                    color: #8594A2;
                                    margin-left: .06rem;
                                }
                            }
                            .site{
                                height: .25rem;
                                display: flex;
                                align-items: center;
                                font-weight: 500;
                                font-size: .15rem;
                                color: #8594A2;
                                img{
                                    width: .23rem;
                                    height: .23rem;
                                }
                                :nth-child(2){
                                    margin: 0rem .06rem;
                                }
                                :last-child{
                                    margin-left: .06rem;
                                }
                            }
                            .workDate{
                                margin: .1rem 0rem .24rem;
                                height: .25rem;
                                display: flex;
                                align-items: center;
                                font-weight: 500;
                                font-size: .15rem;
                                color: #8594A2;
                                img{
                                    width: .23rem;
                                    height: .23rem;
                                }
                            }
                        }
                    }
                }
            }
            .ray-three{
                // margin-top: .38rem;
                width: 14.5rem;
                // height: 484px;
                border: .01rem solid #D7DBE6;
                display: flex;
                flex-direction: column;
                // justify-content: space-between;
                padding: .25rem .25rem 0rem .25rem;
                box-sizing: border-box;
                .ray-service-item{
                    width: 14rem;
                    // height: 212px;
                    background: #F8FAFB;
                    margin-bottom: .25rem;
                    .company-title {
                        padding: 0rem .25rem;
                        box-sizing: border-box;
                        // margin: .47rem 0rem .21rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: .75rem;
                        border-bottom: .01rem solid #D7DBE6;
                        .company-title-left{
                            font-weight: 600;
                            font-size: .15rem;
                            color: #1D1F23;
                            :nth-child(1){
                                margin-right: .13rem;
                            }
                            :last-child{
                                margin-left: .13rem;
                                font-weight: 400;
                                font-size: .13rem;
                                color: #09A966;
                            }
                        }
                        .company-title-right{
                            display: flex;
                            .bargain,.check{
                                justify-content: space-around;
                                align-items: center;
                                display: flex;
                                padding: .07rem .1rem;
                                box-sizing: border-box;
                                border: .01rem solid #09A966;
                                font-weight: 500;
                                font-size: .17rem;
                                color: #09A966;
                                img{
                                    margin-right: .07rem;
                                    width: .25rem;
                                    height: .25rem;
                                }
                            }
                            .bargain{
                                margin-right: .25rem;
                            }
                        }
                    }
                    .comm-company{
                        position: relative;
                        display: flex;
                        width: 100%;
                        height: 1.88rem;
                        padding: .26rem 0rem;
                        box-sizing: border-box;
                        .com-left{
                            // margin: .3rem;
                            padding: 0rem .25rem 0rem .13rem;
                            box-sizing: border-box;
                            img{
                                width: 1.35rem;
                                height: 1.35rem;
                            }
                        }
                        .com-right{
                            display: flex;
                            flex-direction: column;
                            .name{
                                display: flex;
                                :nth-child(1){
                                    font-weight: 500;
                                    font-size: .2rem;
                                    color: #1D1F23;
                                }
                                .border{
                                    margin: 0rem .13rem;
                                    color: #D7DBE6;
                                }
                                .label{
                                    margin-right: .06rem;
                                    display: block;
                                    font-weight: 400;
                                    font-size: .13rem;
                                    color: #09A966;
                                    background: #EDFBF5;
                                    padding: .03rem .09rem;
                                    box-sizing: border-box;
                                }
                            }
                            .good{
                                margin: .06rem 0rem .19rem;
                                display: flex;
                                align-items: center;
                                height: .25rem;
                                img{
                                    width: .17rem;
                                    height: .17rem;
                                }
                                .good-num{
                                    margin: 0rem .06rem;
                                }
                                :last-child{
                                    font-weight: 400;
                                    font-size: .15rem;
                                    color: #8594A2;
                                    margin-left: .06rem;
                                }
                            }
                            .site{
                                height: .25rem;
                                display: flex;
                                align-items: center;
                                font-weight: 500;
                                font-size: .15rem;
                                color: #8594A2;
                                img{
                                    width: .23rem;
                                    height: .23rem;
                                }
                                :nth-child(2){
                                    margin: 0rem .06rem;
                                }
                                :last-child{
                                    margin-left: .06rem;
                                }
                            }
                            .workDate{
                                margin: .1rem 0rem .24rem;
                                height: .25rem;
                                display: flex;
                                align-items: center;
                                font-weight: 500;
                                font-size: .15rem;
                                color: #8594A2;
                                img{
                                    width: .23rem;
                                    height: .23rem;
                                }
                            }
                        }
                    }
                }
            }
            .bottom-btn{
                margin: .63rem 0rem .63rem;
                display: flex;
                justify-content: flex-end;
                .cancel,.submit{
                    width: .85rem;
                    height: .5rem;
                    line-height: .5rem;
                    text-align: center;
                    font-weight: 500;
                    font-size: .17rem;
                }
                .cancel{
                    background: #FFFFFF;
                    color: #09A966;
                    border: .01rem solid #09A966;
                }
                .submit{
                    margin-left: .25rem;
                    background: #09A966;
                    color: #FFFFFF;
                    border: .01rem solid #09A966;
                }
            }
        }
    }
}
</style>