<template>
    <div class="dentistry-three">
        <div class="company-title">选择诊所</div>
        <div class="comm-company">
            <div class="com-left">
                <img :src="company.img" alt="">
            </div>
            <div class="com-right">
                <div class="name">{{company.name}}</div>
                <div class="good">
                    <img src="../../assets/detail/good.png" alt="" v-for="(item,index) in company.good" :key="index">
                    <img src="../../assets/detail/bad.png" alt="" v-for="(item,index) in 5 - company.good" :key="index">
                    <span class="good-num">{{company.good}}.0</span>|<span>{{company.comment}}人评论</span>
                </div>
                <div class="site">
                    <img src="../../assets/detail/site.png" alt="">
                    <span>{{company.site}}</span>|<span>距离我{{company.distance}}</span> 
                </div>
                <div class="workDate">
                    <img src="../../assets/detail/date.png" alt="">
                    <span>{{company.workDate}}</span>
                </div>
            </div>
            <img class="checked" src="../../assets/dentistry/checked.png" alt="">
        </div>
        <div class="subscribe-title">预约模型采集</div>
        <div class="subscribe">
            <el-date-picker
            v-model="date"
            type="date"
            placeholder="选择预约时间">
            </el-date-picker>
        </div>
        <div class="bottom-btn">
            <div class="submit" @click="denAdd">提交</div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                company:{
                    img:require("../../assets/touxiang.png"),
                    name:"东莞市科品快速成型技术有限公司",
                    good:4,
                    comment:230,
                    workDate:"工作日 08:30-18:00",
                    site:"广东省 东莞市 成华大道 二仙桥",
                    isLike:0,
                    distance:"100km",
                },
                date:""
            }
        },
        methods: {
            denAdd(){
                this.$emit('addAct')
            }
        }
    }
</script>

<style lang="scss" scoped>
.dentistry-three {
    .company-title {
        margin: .63rem 0rem .13rem;
        font-weight: 600;
        font-size: .15rem;
        color: #1D1F23;
    }
    .comm-company{
        position: relative;
        display: flex;
        width: 100%;
        height: 1.95rem;
        background: linear-gradient( 180deg, #F6FFFB 0%, #FFFFFF 100%);
        border-radius: 0rem 0rem 0rem 0rem;
        // border: .01rem solid #E8F9F2;
        border: .01rem solid #09A966;
        .com-left{
            margin: .3rem;
            img{
                width: 1.35rem;
                height: 1.35rem;
            }
        }
        .com-right{
            width: 4.38rem;
            display: flex;
            flex-direction: column;
            .name{
                margin: .34rem 0rem .06rem;
                font-weight: 500;
                font-size: .2rem;
                color: #1D1F23;
            }
            .good{
                margin: .06rem 0rem .19rem;
                display: flex;
                align-items: center;
                height: .25rem;
                img{
                    width: .17rem;
                    height: .17rem;
                }
                .good-num{
                    margin: 0rem .06rem;
                }
                :last-child{
                    font-weight: 400;
                    font-size: .15rem;
                    color: #8594A2;
                    margin-left: .06rem;
                }
            }
            .site{
                height: .25rem;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: .15rem;
                color: #8594A2;
                img{
                    width: .23rem;
                    height: .23rem;
                }
                :nth-child(2){
                    margin: 0rem .06rem;
                }
                :last-child{
                    margin-left: .06rem;
                }
            }
            .workDate{
                margin: .1rem 0rem .24rem;
                height: .25rem;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: .15rem;
                color: #8594A2;
                img{
                    width: .23rem;
                    height: .23rem;
                }
            }
        }
        .checked{
            position: absolute;
            right: 0rem;
            top: 0rem;
            width: .38rem;
            height: .38rem;
        }
    }
    .subscribe-title{
        margin: .38rem 0rem .13rem;
        font-weight: 600;
        font-size: .15rem;
        color: #1D1F23;
    }
    .subscribe{
        ::v-deep{
            .el-input__inner{
                width: 6.63rem;
            }
            .el-input__prefix{
                right: .06rem;
                left: unset;
            }
            .el-input__suffix{
                right: 1.56rem;
            }
            .el-date-editor.el-input, .el-date-editor.el-input__inner{
                width: 6.63rem;
            }
        }
    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        .cancel,.submit{
            width: .85rem;
            height: .5rem;
            line-height: .5rem;
            text-align: center;
            font-weight: 500;
            font-size: .17rem;
        }
        .submit{
            margin-left: .25rem;
            background: #09A966;
            color: #FFFFFF;
            border: .01rem solid #09A966;
        }
    }
}
</style>