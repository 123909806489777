const userInfo = {
    state: {
        user:{},
        token:"",
        lat:"36.642849",
        lon:"114.481898",
    },

    mutations: {
        fixInfo:(state , val)=>{
            state.user = val
            console.log(state.user,"用户信息");
        },
        fixToken:(state , val)=>{
            state.token = val
            console.log(state.token,"token");
        }
    }
}

export default userInfo
