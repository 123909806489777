import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/layout";

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: '/',
    redirect: "/home",
    component: Layout,
    children:[
      {
        path: '/register',
        name: 'register',
        component: () => import('@/views/login/register'),
        moduleName: '注册',
      },
      {
        path: '/forget',
        name: 'forget',
        component: () => import('@/views/login/forget'),
        moduleName: '忘记密码',
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/login'),
        moduleName: '登录',
      },
      {
        path: '/home',
        name: 'home',
        meta: { refresh: true },
        component: resolve => require(["@/views/home/index"], resolve),
        moduleName: '首页',
      },
      {
        path: '/detail/:id',
        name: 'detail',
        component: () => import('@/views/home/detail'),
        moduleName: '商品详情页',
      },
      {
        path: '/dentistry',
        name: 'dentistry',
        component: () => import('@/views/dentistry/index'),
        moduleName: '医学齿科',
      },
      {
        path: '/dentistryDetail/:id',
        name: 'dentistryDetail',
        component: () => import('@/views/dentistry/detail'),
        moduleName: '医学齿科详情',
      },
      {
        path: '/automobile',
        name: 'automobile',
        component: () => import('@/views/automobile/index'),
        moduleName: '汽车制造',
      },
      {
        path: '/automobileDetail/:id',
        name: 'automobileDetail',
        component: () => import('@/views/automobile/detail'),
        moduleName: '汽车制造详情',
      },
      {
        path: '/facilitator',
        name: 'facilitator',
        component: () => import('@/views/facilitator/index'),
        moduleName: '服务商',
      },
      {
        path: '/platformServe',
        name: 'platformServe',
        component: () => import('@/views/platformServe/marketing'),
        moduleName: '平台服务',
      },
      {
        path: '/facilitatorDetail/:id',
        name: 'facilitatorDetail',
        component: () => import('@/views/facilitator/detail'),
        moduleName: '服务商详情',
      },
      {
        path: "/layout",
        name: "layout",
        component: ()=> import('@/views/layout/Index'),
        redirect: '/layout/personal',
        children: [{
          path: "personal",
          name: "personal",
          component: ()=> import('@/views/layout/personal/Index')
        },{
          path: "myModel",
          name: "myModel",
          component: ()=> import('@/views/layout/myModel/Index')
        },{
          path: "personalInfo",
          name: "personalInfo",
          component: ()=> import('@/views/layout/personalInfo/Index')
        },{
          path: "securityInfo",
          name: "securityInfo",
          component: ()=> import('@/views/layout/securityInfo/Index')
        },{
          path: "myCollect",
          name: "myCollect",
          component: ()=> import('@/views/layout/myCollect/Index')
        },{
          path: "myCare",
          name: "myCare",
          component: ()=> import('@/views/layout/myCare/Index')
        },{
          path: "myNeed",
          name: "myNeed",
          component: ()=> import('@/views/layout/myNeed/Index')
        },{
          path: "myOrder",
          name: "myOrder",
          component: ()=> import('@/views/layout/myOrder/Index')
        },{
          path: "myWallet",
          name: "myWallet",
          component: ()=> import('@/views/layout/myWallet/Index')
        },]
      }
    ]
  },
]


export default new VueRouter({
  mode: "hash", 
  scrollBehavior: () => ({ y: 0 }),
  routes
});

// const router = new VueRouter({
//   routes
// })

// export default router
