const automobile = {
    state: {
        autoAct:1,
    },

    mutations: {
        autoAdd:(state)=>{
            state.autoAct = state.autoAct += 1;
            console.log(state.autoAct,"autoAct")
        },
        autoMines:(state)=>{
            state.autoAct = state.autoAct -= 1;
        },
        closeAuto:(state)=>{
            state.autoAct = 1;
        }
    }
}

export default automobile
