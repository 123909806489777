const model = {
    state: {
        ModAct:1,
        info:{
            
        },
        equity:{}
    },

    mutations: {
        demAdd:(state)=>{
            state.ModAct = state.ModAct += 1;
            console.log(state.ModAct,"ModAct")
        },
        demMines:(state)=>{
            state.ModAct = state.ModAct -= 1;
        },
        setEquity:(state,payload)=>{
            state.equity = payload;
        }
    }
}

export default model