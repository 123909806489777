<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'App',
    metaInfo() {
        return {

        }
    },
    computed: {

    },
    
}
</script>

<style lang="scss" scope>
*{
    padding: 0;
    margin: 0;
}
#app{
    // min-width: 1400px;
    overflow-x: hidden;
}
</style>
