<template>
    <div class="dentistryFour">
        <div class="model-title">
            <span>牙齿X光片</span>
            <span>请上传JPG、JPEG、PNG、PDF、WOD格式文件</span>
        </div>
        <div class="model">
            <el-upload
            class="upload-demo"
            action=""
            ref="upload"
            auto-upload:false
            accept=".png"
            :limit="1"
            :file-list="file"
            :show-file-list="true"
            :http-request="uploadFile"
            multiple>
                <div class="upload">
                    <img src="../../assets/dentistry/upload.png" alt="">
                </div>
                <div class="el-upload__text">请上传STL、OBJ、AMF、3MF、STEP格式文件</div>
                <div class="file-list" slot="file" v-if="file.length > 0">
                    <div class="list">
                        <div class="file-top">
                            <div class="file-top-left">
                                <img :src="file[0].img" alt="">
                            </div>
                            <div class="file-top-right">
                                <div class="file-top-right-top">{{file[0].name}}</div>
                                <div class="file-top-right-bottom">文件大小：{{file[0].size}}MB</div>
                            </div>
                        </div>
                        <div class="file-bot"></div>
                        <img class="wrong" src="../../assets/wrong.png" alt="" @click="removeFile">
                    </div>
                </div>
            </el-upload>
        </div>
        <div class="bottom-btn">
            <div class="save">保存模型</div>
            <div class="submit" @click="denAdd">下一步</div>
        </div>
    </div>
</template>

<script>
import { cosUpload } from '../../utils/cosUtils';
    export default {
        data(){
            return{
                file: []
            }
        },
        methods: {
            denAdd(){
                this.$emit('addAct')
            },
            // 上传图片
            removeFile(){
                this.$refs.upload.clearFiles()
                this.file = []
            },
            // 上传文件
            async uploadFile(item) {
                const fileUrl = await cosUpload(item.file);
                console.log(fileUrl,"文件上传");
                this.file.push({
                    img:fileUrl,
                    name:item.file.name,
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
.dentistryFour{
    .model-title{
        margin: .63rem 0rem .13rem;
        :nth-child(1){
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-right: .1rem;
        }
        :last-child{
            margin-left: .13rem;
            font-weight: 400;
            font-size: .13rem;
            color: #8594A2;
        }
    }
    .model{
        padding: .38rem;
        box-sizing: border-box;
        width: 14.5rem;
        height: 6.1rem;
        border-radius: 0rem 0rem 0rem 0rem;
        border: .01rem solid #D7DBE6;
        .upload{
            width: 2.13rem;
            height: 2.13rem;
            background: url("../../assets/dentistry/upload-bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: .6rem;
                height: .6rem;
            }
        }
        .file-list{
            position: relative;
            padding: .19rem .29rem 0rem .23rem;
            box-sizing: border-box;
            width: 100%;
            height: .93rem;
            background: #F8FAFB;
            display: flex;
            flex-direction: column;
            .list{
                .file-top{
                    display: flex;
                    .file-top-left{
                        img{
                            width: .4rem;
                            height: .4rem;
                        }
                        margin-right: .13rem;
                    }
                    .file-top-right{
                        display: flex;
                        flex-direction: column;
                        .file-top-right-top{
                            font-weight: 500;
                            font-size: .15rem;
                            color: #788086;
                        }
                        .file-top-right-bottom{
                            font-weight: 300;
                            font-size: .13rem;
                            color: #788086;
                        }
                    }
                }
                .wrong{
                    cursor: pointer;
                    width: .2rem;
                    height: .2rem;
                    position: absolute;
                    right: .13rem;
                }
            }
        }
        ::v-deep{
            .el-upload{
                width: 13.75rem;
                height: 4.05rem;
                border: .01rem dashed #D7DBE6;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .el-upload__text{
                font-weight: 400;
                font-size: .15rem;
                color: #8594A2;
            }
        }
    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        font-weight: 500;
        font-size: .17rem;
        .save{
            margin-right: .25rem;
            width: 1.2rem;
            height: .5rem;
            text-align: center;
            line-height: .5rem;
            background: #FFFFFF;
            border-radius: 0rem 0rem 0rem 0rem;
            border: .01rem solid #09A966;
            color: #09A966;
        }
        .submit{
            width: 1.02rem;
            height: .5rem;
            text-align: center;
            color: #788086;
            line-height: .5rem;
            background: #EFEFEF;
        }
    }
}
</style>