const getters = {
    token: state => state.userInfo.token,
    // avatar: state => state.user.avatar,
    user: state => state.userInfo.user,
    lat: state => state.userInfo.lat,
    lon: state => state.userInfo.lon,

    drawFlag: state => state.alert.drawFlag,
    denFlag: state => state.alert.denFlag,
    demFlag: state => state.alert.demFlag,
    autoFlag: state => state.alert.autoFlag,
    modelFlag: state => state.alert.modelFlag,
    addDemFlag: state => state.alert.addDemFlag,
    chooseDemFlag: state => state.alert.chooseDemFlag,
    equityFlag: state => state.alert.equityFlag,
    equityStatus: state => state.alert.equityStatus,

    denAct: state => state.dentistry.denAct,

    demAct: state => state.demand.demAct,
    info: state => state.demand.info,
    shopId: state => state.demand.shopId,
    material: state => state.demand.material,
    materialItem: state => state.demand.materialItem,
    product: state => state.demand.product,
    productItem: state => state.demand.productItem,
    serviceItem: state => state.demand.serviceItem,

    ModAct: state => state.model.ModAct,
    equity: state => state.model.equity,

    autoAct: state => state.automobile.autoAct,

    Ract: state => state.router.Ract,
    routerChild: state => state.router.routerChild,
    orderType: state => state.router.orderType,
    
    modelType: state => state.type.modelType,
    demandType: state => state.type.demandType,
}
export default getters
