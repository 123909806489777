const alert = {
    state: {
        drawFlag: false,  //制图开关
        denFlag: false,  //齿科弹框开关
        demFlag: false,  //需求弹窗开关
        autoFlag: false,  //汽车弹框开关
        modelFlag: false,  //添加模型弹框开关
        addDemFlag: false,  //添加需求弹框开关
        chooseDemFlag: false,  //选择需求弹框开关
        equityFlag: false,  //产权弹框开关
        equityStatus: true,  //产权弹框状态
    },

    mutations: {
        // 制图开关
        setDrawFlag:(state,val)=>{
            state.drawFlag = val;
            console.log(state.drawFlag);
        },
        // 齿科开关
        openDenFlag:(state)=>{
            state.denFlag = true;
        },
        closeDenFlag:(state)=>{
            state.denFlag = false;
        },
        // 需求开关
        openDemFlag:(state)=>{
            state.demFlag = true;
        },
        closeDemFlag:(state)=>{
            state.demFlag = false;
        },
        // 汽车开关
        openAutoFlag:(state)=>{
            state.autoFlag = true;
        },
        closeAutoFlag:(state)=>{
            state.autoFlag = false;
        },
        // 添加需求模型开关
        openAddDemFlag:(state)=>{
            state.addDemFlag = true;
        },
        closeAddDemFlag:(state)=>{
            state.addDemFlag = false;
        },
        // 选择需求模型开关
        openChooseDemFlag:(state)=>{
            state.chooseDemFlag = true;
        },
        closeChooseDemFlag:(state)=>{
            state.chooseDemFlag = false;
        },
        // 模型开关
        openModelFlag:(state)=>{
            state.modelFlag = true;
        },
        closeModelFlag:(state)=>{
            state.modelFlag = false;
        },
        // 产权开关
        setequity:(state,payload)=>{
            state.equityFlag = payload;
        },
        setequityStatus:(state,payload)=>{
            state.equityStatus = payload;
        }
    }
}

export default alert
