import COS from 'cos-js-sdk-v5';
import request from '@/utils/request';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';

// 不同项目可能不同
var Bucket = 'soft-sea-1317194318'; // 存储桶名称
var Region = 'ap-beijing'; // 存储桶Region
var folderName = 'softSeaFolder'; // 图片存放的文件夹

// 初始化实例
var cosInstance = new COS({
  // 获取签名
  getAuthorization: function(options, callback) {
    request({
      url: "api/system.config/getStsConfig",
      method: 'get',
    }).then(res => {
      var credentials = res.data.credentials;
      if (!res || !credentials) return console.error('credentials invalid');
      callback({
        TmpSecretId: credentials.tmpSecretId,
        TmpSecretKey: credentials.tmpSecretKey,
        XCosSecurityToken: credentials.sessionToken,
        StartTime: res.data.startTime, // 时间戳，单位秒，如：1580000000，建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
        ExpiredTime: res.data.expiredTime, // 时间戳，单位秒，如：1580000900
      });
    });
  },
});

// 上传oss
export async function cosUpload(file, callback) {
  let fileUrl = 'https://';
  const id = uuidv4();
  var suffix = file.name.match(/.[^.]+$/)[0];
  const fileName = `${folderName}/${id}${suffix}`;
  try {
    var data = await cosInstance.uploadFile({
      Bucket: Bucket,
      Region: Region,
      Key: fileName,
      Body: file,
      Sign: true,
      SliceSize: 1024 * 1024 * 3, // 大于3mb才进行分块上传
      onTaskReady: function(taskId) {
        /* 执行队列taskId */
        // console.log('taskId:', taskId);
      },
      onProgress: function(info) {
        var percent = parseInt(info.percent * 10000) / 100;
        var speed = parseInt((info.speed / 1024 / 1024) * 100) / 100;
        callback(percent);
      },
    });

    fileUrl += data.Location;
    // console.log(data, 54)
    return fileUrl;
  } catch (err) {
    return null;
  }
}
// 上传oss 分块上传 暂时没用
export async function cosSliceUploadFile(fileObject) {
  let fileUrl = 'https://';
  const id = uuidv4();
  const fileName = `${folderName}/${id}-${file.name}`;
  console.log(fileName, '66');
  try {
    console.log(fileName, 67);
    var data = await cosInstance.sliceUploadFile({
      Bucket: Bucket /* 填入您自己的存储桶，必须字段 */,
      Region: Region /* 存储桶所在地域，例如ap-beijing，必须字段 */,
      Key: fileName /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
      Body: fileObject /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */,
      onTaskReady: function(taskId) {
        /* 非必须 */
        console.log(taskId);
      },
      onHashProgress: function(progressData) {
        /* 非必须 */
        console.log(JSON.stringify(progressData));
      },
      onProgress: function(progressData) {
        /* 非必须 */
        console.log(JSON.stringify(progressData));
      },
    });

    fileUrl += data.Location;
    // console.log(data, 54)
    return fileUrl;
  } catch (err) {
    return null;
  }
}

// 下载oss文件
export function cosDownload(file, fileName) {
  return new Promise((resolve, reject) => {
    if (file && file.indexOf('soft-sea-1317194318.cos.ap-beijing.myqcloud.com') === -1) {
      resolve(file);
    }
    const key = file && file.split('beijing.myqcloud.com')[1];
    if (!key) return '';
    // 目前只支持回调方式。
    cosInstance.getObjectUrl(
      {
        Bucket /* 填写自己的 bucket，必须字段 */,
        Region /* 存储桶所在地域，必须字段 */,
        Key: key /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
        Sign: true,
        Expires: 3600, // 单位秒
        ResponseContentDisposition: 'attachment;filename=FileName.txt',
      },
      function(err, data) {
        if (err) return console.log(err);
        const aa = file.substring(file.lastIndexOf("."));
        
        /* 通过指定 response-content-disposition=attachment 实现强制下载 */
        var downloadUrl =
          data.Url +
          (data.Url.indexOf('?') > -1 ? '&' : '?') +
          `response-content-disposition=attachment;filename=${fileName}${aa}`;
        /* 可拼接 filename 来实现下载时重命名 */
        /* downloadUrl += ';filename=myname'; */
        // （推荐使用 window.open()方式）这里是新窗口打开 url，如果需要在当前窗口打开，可以使用隐藏的 iframe 下载，或使用 a 标签 download 属性协助下载
        // callback(downloadUrl)
        resolve(downloadUrl);
        // window.open(downloadUrl);
      }
    );
  });  
}

// 下载指定地址文件
export function downloadURL(url, name = '') {
  let link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  if (name) {
    link.download = name; // 下载文件名
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

// 删除oss --目前没用
export function deletePrivateOss(picName) {
  cosInstance.deleteObject(
    {
      Bucket: Bucket /* 填写自己的 bucket，必须字段 */,
      Region: Region /* 存储桶所在地域，必须字段 */,
      Key: picName /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */,
    },
    function(err, data) {
      console.log(err || data);
    }
  );
}
