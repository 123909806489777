<template>
    <div class="dentistrySix">
        <div class="company-title">
            <div class="company-title-left">
                打印耗材服务商
            </div>
        </div>
        <div class="comm-company">
            <div class="com-left">
                <img :src="company.img" alt="">
            </div>
            <div class="com-right">
                <div class="name"><span>{{company.name}}</span><span class="border">|</span><span v-for="(item,index) in company.label" :key="index" class="label">{{item}}</span></div>
                <div class="good">
                    <img src="../../assets/detail/good.png" alt="" v-for="(item,index) in company.good" :key="index">
                    <img src="../../assets/detail/bad.png" alt="" v-for="(item,index) in 5 - company.good" :key="index">
                    <span class="good-num">{{company.good}}.0</span>|<span>{{company.comment}}人评论</span>
                </div>
                <div class="site">
                    <img src="../../assets/detail/site.png" alt="">
                    <span>{{company.site}}</span>|<span>距离我{{company.distance}}</span> 
                </div>
                <div class="workDate">
                    <img src="../../assets/detail/date.png" alt="">
                    <span>{{company.workDate}}</span>
                </div>
            </div>
        </div>
        <div class="subscribe-title">预约手术</div>
        <div class="subscribe">
            <!-- <el-date-picker
            v-model="date"
            type="date"
            placeholder="选择预约时间">
            </el-date-picker> -->
            <el-date-picker
            v-model="date"
            type="datetime"
            placeholder="选择日期时间">
            </el-date-picker>
        </div>
        <div class="examine">
            <div class="examine-box">
                <div class="examine-box-left">
                    <div class="examine-left-title">
                        <span>主刀医生</span>
                    </div>
                    <div class="img">
                        <img :src="doctorInfo.img" alt="">
                    </div>
                    <div class="size">{{doctorInfo.post}}<span class="border">|</span><span>{{doctorInfo.date}}</span></div>
                    <div class="name">{{doctorInfo.name}}</div>
                </div>
                <div class="examine-box-right">
                    <div class="examine-right-title">手术报告</div>
                    <div class="examine-right-box"></div>
                </div>
            </div>
        </div>
        <div class="bottom-btn">
            <div class="submit" @click="denAdd">下一步</div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                company:{
                    img:require("../../assets/touxiang.png"),
                    label:["3D建模","产品打印"],
                    name:"东莞市科品快速成型技术有限公司",
                    good:4,
                    comment:230,
                    workDate:"工作日 08:30-18:00",
                    site:"广东省 东莞市 成华大道 二仙桥",
                    isLike:0,
                    distance:"100km",
                },
                date:"",
                doctorInfo:{
                    img:require("../../assets/dentistry/listOne.png"),
                    date:"10年临床经验",
                    post:"辉煌牙科医院主治大夫",
                    name:"安宏"
                }
            }
            
        },
        methods: {
            denAdd(){
                // this.$emit('addAct')
                this.$store.commit('closeDenFlag')
                this.$store.commit('CloseDen')
            }
        }
    }
</script>

<style lang="scss" scoped>
.dentistrySix{
    .company-title {
        margin: .47rem 0rem .21rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .company-title-left{
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
        }
        .company-title-right{
            display: flex;
            .bargain,.check{
                justify-content: space-around;
                align-items: center;
                display: flex;
                width: 1.05rem;
                height: .4rem;
                border-radius: 0rem 0rem 0rem 0rem;
                border: .01rem solid #09A966;
                font-weight: 500;
                font-size: .17rem;
                color: #09A966;
                img{
                    width: .25rem;
                    height: .25rem;
                }
            }
            .bargain{
                margin-right: .25rem;
            }
        }
    }
    .comm-company{
        position: relative;
        display: flex;
        width: 100%;
        height: 1.95rem;
        .com-left{
            margin: .3rem;
            img{
                width: 1.35rem;
                height: 1.35rem;
            }
        }
        .com-right{
            display: flex;
            flex-direction: column;
            .name{
                display: flex;
                :nth-child(1){
                    font-weight: 500;
                    font-size: .2rem;
                    color: #1D1F23;
                }
                .border{
                    margin: 0rem .13rem;
                    color: #D7DBE6;
                }
                .label{
                    margin-right: .06rem;
                    display: block;
                    font-weight: 400;
                    font-size: .13rem;
                    color: #09A966;
                    background: #EDFBF5;
                    padding: .03rem .09rem;
                    box-sizing: border-box;
                }
            }
            .good{
                margin: .06rem 0rem .19rem;
                display: flex;
                align-items: center;
                height: .25rem;
                img{
                    width: .17rem;
                    height: .17rem;
                }
                .good-num{
                    margin: 0rem .06rem;
                }
                :last-child{
                    font-weight: 400;
                    font-size: .15rem;
                    color: #8594A2;
                    margin-left: .06rem;
                }
            }
            .site{
                height: .25rem;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: .15rem;
                color: #8594A2;
                img{
                    width: .23rem;
                    height: .23rem;
                }
                :nth-child(2){
                    margin: 0rem .06rem;
                }
                :last-child{
                    margin-left: .06rem;
                }
            }
            .workDate{
                margin: .1rem 0rem .24rem;
                height: .25rem;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: .15rem;
                color: #8594A2;
                img{
                    width: .23rem;
                    height: .23rem;
                }
            }
        }
    }
    .subscribe-title{
        margin: .38rem 0rem .13rem;
        font-weight: 600;
        font-size: .15rem;
        color: #1D1F23;
    }
    .subscribe{
        ::v-deep{
            .el-input__inner{
                width: 6.63rem;
            }
            .el-input__prefix{
                right: .06rem;
                left: unset;
            }
            .el-input__suffix{
                right: 1.56rem;
            }
            .el-date-editor.el-input, .el-date-editor.el-input__inner{
                width: 6.63rem;
            }
        }
    }
    .examine{
        .examine-box{
            width: 14.5rem;
            height: 6.08rem;
            border-radius: 0rem 0rem 0rem 0rem;
            // border: .01rem solid #D7DBE6;
            display: flex;
            .examine-box-left{
                margin:.25rem .38rem 0rem 0rem;
                width: 3.25rem;
                display: flex;
                flex-direction: column;
                .examine-left-title{
                    height: .25rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span{
                        font-weight: 600;
                        font-size: .15rem;
                        color: #1D1F23;
                    }
                }
                .img{
                    margin-top: .13rem;
                    width: 3.25rem;
                    height: 3.75rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .size{
                    .border{
                        margin: 0rem .06rem;
                    }
                    :last-child{
                        color: rgba(9, 169, 102, 1);
                    }
                    height: .46rem;
                    line-height: .46rem;
                    font-weight: 400;
                    font-size: .15rem;
                    color: #8594A2;
                    border-bottom: .01rem solid #F5F5F5;
                }
                .name{
                    margin-top: .1rem;
                    font-weight: 600;
                    font-size: .2rem;
                    color: #1D1F23;
                }
                .btn{
                    margin-left: calc(50% - .61rem);
                    margin-top: .28rem;
                    width: 1.23rem;
                    height: .4rem;
                    line-height: .4rem;
                    text-align: center;
                    background: #09A966;
                    font-weight: 400;
                    font-size: .17rem;
                    color: #FFFFFF;
                }
            }
            .examine-box-right{
                width: 10.13rem;
                .examine-right-title{
                    font-weight: 600;
                    font-size: .15rem;
                    color: #1D1F23;
                    height: .25rem;
                    margin: .25rem 0rem .13rem;
                }
                .examine-right-box{
                    width: 10.88rem;
                    height: 4.59rem;
                    border-radius: 0rem 0rem 0rem 0rem;
                    border: .01rem solid #D7DBE6;
                }
            }
        }
    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        font-weight: 500;
        font-size: .17rem;
        .submit{
            width: 1.02rem;
            height: .5rem;
            color: #788086;
            font-weight: 500;
            font-size: .17rem;
            color: #FFFFFF;
            text-align: center;
            line-height: .5rem;
            background: #09A966;
        }
    }
}
</style>