const router = {
    state: {
        Ract:1,
        routerChild:0,
        orderType:0,   //订单状态：0 已关闭；1 待付款；2 待发货；3 待收货；4 待评价；5 已完成；6 已退款； 
    },

    mutations: {
        fixAct:(state,index)=>{
            state.Ract = index
            console.log(state.Ract,"demAct")
        },
        setRouter:(state,index)=>{
            state.routerChild = index
        },
        setOrder:(state,index)=>{
            state.orderType = index
        }
    }
}

export default router
