<template>
    <div class="automobile">
        <div class="automobileMark">
            <div class="title">
                <span>定制零部件</span>
                <img src="../../assets/wrong.png" alt="" @click="closeAlert">
            </div>
            <AutoTitle></AutoTitle>
            <AutoMobileOne @addAct='changeActive' @closeAlt='closeAlert' v-if="$store.getters.autoAct == 1"></AutoMobileOne>
            <AutoMobileTwo @addAct='changeActive' v-if="$store.getters.autoAct == 2"></AutoMobileTwo>
            <AutoMobileThree @addAct='changeActive' v-if="$store.getters.autoAct == 3"></AutoMobileThree>
            <AutoMobileFour @addAct='changeActive' v-if="$store.getters.autoAct == 4"></AutoMobileFour>
            <AutoMobileFive @addAct='changeActive' v-if="$store.getters.autoAct == 5"></AutoMobileFive>
            <AutoMobileSix @addAct='changeActive' v-if="$store.getters.autoAct == 6"></AutoMobileSix>
        </div>
    </div>
</template>

<script>
import AutoTitle from './steps.vue';
import AutoMobileOne from "./automobileOne.vue";
import AutoMobileTwo from "./automobileTwo.vue";
import AutoMobileThree from "./automobileThree.vue";
import AutoMobileFour from "./automobileFour.vue";
import AutoMobileFive from "./automobileFive.vue";
import AutoMobileSix from "./automobileSix.vue";
    export default {
        components: {
            AutoTitle,
            AutoMobileOne,
            AutoMobileTwo,
            AutoMobileThree,
            AutoMobileFour,
            AutoMobileFive,
            AutoMobileSix
        },
        methods: {
            closeAlert() {
                this.$store.commit('closeAutoFlag')
            },
            changeActive() {
                if(this.$store.getters.autoAct < 6){
                    this.$store.commit("autoAdd")
                }else{
                    this.closeAlert()
                }
            },
            autoMin(){
                this.$store.commit("autoMines")
            }
        }
    }
</script>

<style lang="scss" scoped>
.automobile{
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    .automobileMark{
        box-sizing: border-box;
        position: fixed;
        top: 1.31rem;
        left: 3.25rem;
        height: 7.5rem;
        overflow-x: auto;
        padding: 0rem 1.5rem;
        box-sizing: border-box;
        background: linear-gradient( 180deg, #F6FFFB 0%, #FFFFFF 100%);
        .title{
            margin: .75rem 0rem .56rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            span{
                font-weight: 500;
                font-size: .25rem;
                color: #1D1F23;
            }
            img{
                cursor: pointer;
                width: .35rem;
                height: .35rem;
            }
        }
    }
}
</style>