<template>
    <div class="save">
        <div class="automobileMark">
            <div class="title">
                <span>新建模型</span>
                <img src="../../assets/wrong.png" alt="" @click="closeAlert">
            </div>
            <ModelOne />
        </div>
    </div>
</template>

<script>
import ModelOne from "./modelOne.vue"
    export default {
        components: {
            ModelOne
        },
        data(){
            return{
                rayFlag: true
            }
        },
        methods:{
            closeAlert() {
                this.$store.commit('closeModelFlag')
            },
            changeActive() {
                this.$store.commit("denAdd")
            }
        }
    }
</script>

<style lang="scss" scoped>
.save{
    z-index: 99;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    .automobileMark{
        box-sizing: border-box;
        position: fixed;
        top: 1.31rem;
        left: 3.25rem;
        height: 7.5rem;
        overflow-x: auto;
        padding: 0rem 1.5rem;
        box-sizing: border-box;
        background: linear-gradient( 180deg, #F6FFFB 0%, #FFFFFF 100%);
        .title{
            margin: .75rem 0rem .56rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            span{
                font-weight: 500;
                font-size: .25rem;
                color: #1D1F23;
            }
            img{
                cursor: pointer;
                width: .35rem;
                height: .35rem;
            }
        }
    }
}
</style>