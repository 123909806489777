<template>
    <div class="dentistryAlt">
        <div class="dentistryMark">
            <div class="title">
                <span>种植牙服务</span>
                <img src="../../assets/wrong.png" alt="" @click="closeAlert">
            </div>
            <DemandTitle></DemandTitle>
            <DentistryOne @addAct='changeActive' v-if="$store.getters.denAct == 1"></DentistryOne>
            <DentistryTwo @addAct='changeActive' v-if="$store.getters.denAct == 2"></DentistryTwo>
            <DentistryThree @addAct='changeActive' v-if="$store.getters.denAct == 3"></DentistryThree>
            <DentistryFour @addAct='changeActive' v-if="$store.getters.denAct == 4"></DentistryFour>
            <DentistryFive @addAct='changeActive' v-if="$store.getters.denAct == 5"></DentistryFive>
            <DentistrySix @addAct='changeActive' v-if="$store.getters.denAct == 6"></DentistrySix>
        </div>
    </div>
</template>

<script>
import DemandTitle from './steps.vue';
import DentistryOne from "./dentistryOne.vue"
import DentistryTwo from "./dentistryTwo.vue"
import DentistryThree from "./dentistryThree.vue"
import DentistryFour from "./dentistryFour.vue"
import DentistryFive from "./dentistryFive.vue"
import DentistrySix from "./dentistrySix.vue"
    export default {
        name: 'denAlt',
        components: {
            DemandTitle,
            DentistryOne,
            DentistryTwo,
            DentistryThree,
            DentistryFour,
            DentistryFive,
            DentistrySix
        },
        data() {
            return {
                
            }
        },
        methods: {
            closeAlert() {
                this.$store.commit('closeDenFlag')
            },
            changeActive() {
                if(this.$store.getters.denAct < 6){
                    this.$store.commit("denAdd")
                }else{
                    this.closeAlert()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.dentistryAlt{
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    .dentistryMark{
        box-sizing: border-box;
        position: fixed;
        top: 1.31rem;
        left: 3.25rem;
        height: 7.5rem;
        overflow-x: auto;
        padding: 0rem 1.5rem;
        box-sizing: border-box;
        background: linear-gradient( 180deg, #F6FFFB 0%, #FFFFFF 100%);
        .title{
            margin: .75rem 0rem .56rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            span{
                font-weight: 500;
                font-size: .25rem;
                color: #1D1F23;
            }
            img{
                cursor: pointer;
                width: .35rem;
                height: .35rem;
            }
        }
    }
}
</style>