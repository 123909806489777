<template>
    <div class="dentistryFive">
        <div class="model-title">模型数据</div>
        <div class="model">
            <div class="model-left">
                <img :src="model.img" alt="">
            </div>
            <div class="model-right">
                <div class="model-right-top">{{model.name}}</div>
                <div class="model-right-bot">
                    <span class="title">长宽高：</span><span class="val">{{model.size}}</span>
                    <span class="title">体积：</span><span class="val">{{model.volume}}</span>
                    <span class="title">表面积：</span><span class="val">{{model.area}}</span>
                </div>
            </div>
        </div>
        <div class="company-title">
            <div class="company-title-left">
                打印耗材服务商
            </div>   
            <div class="company-title-right">
                <div class="bargain">
                    <img src="" alt="">
                    <span>比个价</span>
                </div>
                <div class="check">
                    <img src="" alt="">
                    <span>去选择</span>
                </div>
            </div>
        </div>
        <div class="comm-company">
            <div class="com-left">
                <img :src="company.img" alt="">
            </div>
            <div class="com-right">
                <div class="name"><span>{{company.name}}</span><span class="border">|</span><span v-for="(item,index) in company.label" :key="index" class="label">{{item}}</span></div>
                <div class="good">
                    <img src="../../assets/detail/good.png" alt="" v-for="(item,index) in company.good" :key="index">
                    <img src="../../assets/detail/bad.png" alt="" v-for="(item,index) in 5 - company.good" :key="index">
                    <span class="good-num">{{company.good}}.0</span>|<span>{{company.comment}}人评论</span>
                </div>
                <div class="site">
                    <img src="../../assets/detail/site.png" alt="">
                    <span>{{company.site}}</span>|<span>距离我{{company.distance}}</span> 
                </div>
                <div class="workDate">
                    <img src="../../assets/detail/date.png" alt="">
                    <span>{{company.workDate}}</span>
                </div>
            </div>
        </div>
        <div class="materials-title">
            <span>选择打印耗材</span>
            <span>根据模型信息，有以下耗材可供选择</span>
        </div>
        <div class="materials">
            <div v-for="(item,index) in materialsList" :key="item.id" :class="materAct == index ? 'item active' : 'item'" @click="cutMater(index)">
                <div class="img">
                    <img :src="item.img" alt="">
                </div>
                <div class="name">{{item.name}}</div>
                <div class="item-price">
                   <div>耗材费用：</div>
                   <div class="price"><span>￥</span><span>{{item.price}}</span><span>/件</span></div>
                </div>
            </div>
        </div>
        <div class="bottom-btn">
            <div class="submit" @click="denAdd">下一步</div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                company:{
                    img:require("../../assets/touxiang.png"),
                    label:["3D建模","产品打印"],
                    name:"东莞市科品快速成型技术有限公司",
                    good:4,
                    comment:230,
                    workDate:"工作日 08:30-18:00",
                    site:"广东省 东莞市 成华大道 二仙桥",
                    isLike:0,
                    distance:"100km",
                },
                model:{
                    img:require("../../assets/detail/mater-one.png"),
                    name:"患者-牙齿模型.stl",
                    size:"24x8x2mm",
                    volume:"384mm²",
                    area:"500mm³"
                },
                materialsList:[
                    {
                        id:1,
                        img:require("../../assets/dentistry/mater-one.png"),
                        price:"50",
                        name:"陶瓷"
                    },
                    {
                        id:2,
                        img:require("../../assets/dentistry/mater-two.png"),
                        price:"50",
                        name:"钛合金"
                    },
                ],
                materAct:0,
            }
        },
        methods:{
            cutMater(index){
                console.log("触发");
                this.materAct = index;
            },
            denAdd(){
                this.$emit('addAct')
            },
        }
    }
</script>

<style lang="scss" scoped>
.dentistryFive{
    .model-title{
        margin: .63rem 0rem .13rem;
        font-weight: 600;
        font-size: .15rem;
        color: #1D1F23;
    }
    .model{
        display: flex;
        // width: 14.5rem;
        width: 100%;
        height: 1.4rem;
        background: #F8FAFB;
        border-radius: 0rem 0rem 0rem 0rem;
        border: .01rem solid #F8FAFB;
        .model-left{
            img{
                margin: .13rem .23rem;
                width: 1.15rem;
                height: 1.15rem;
            }
        }
        .model-right{
            .model-right-top{
                font-weight: 500;
                font-size: .17rem;
                color: #1D1F23;
                margin: .4rem 0rem .17rem;
            }
            .model-right-bot{
                font-weight: 300;
                font-size: .13rem;
                color: #788086;
                .val{
                    margin-right: .13rem;
                }
            }
        }
    }
    .company-title {
        margin: .47rem 0rem .21rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .company-title-left{
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
        }
        .company-title-right{
            display: flex;
            .bargain,.check{
                justify-content: space-around;
                align-items: center;
                display: flex;
                width: 1.05rem;
                height: .4rem;
                border-radius: 0rem 0rem 0rem 0rem;
                border: .01rem solid #09A966;
                font-weight: 500;
                font-size: .17rem;
                color: #09A966;
                img{
                    width: .25rem;
                    height: .25rem;
                }
            }
            .bargain{
                margin-right: .25rem;
            }
        }
    }
    .comm-company{
        position: relative;
        display: flex;
        width: 100%;
        height: 1.95rem;
        .com-left{
            margin: .3rem;
            img{
                width: 1.35rem;
                height: 1.35rem;
            }
        }
        .com-right{
            display: flex;
            flex-direction: column;
            .name{
                display: flex;
                :nth-child(1){
                    font-weight: 500;
                    font-size: .2rem;
                    color: #1D1F23;
                }
                .border{
                    margin: 0rem .13rem;
                    color: #D7DBE6;
                }
                .label{
                    margin-right: .06rem;
                    display: block;
                    font-weight: 400;
                    font-size: .13rem;
                    color: #09A966;
                    background: #EDFBF5;
                    padding: .03rem .09rem;
                    box-sizing: border-box;
                }
            }
            .good{
                margin: .06rem 0rem .19rem;
                display: flex;
                align-items: center;
                height: .25rem;
                img{
                    width: .17rem;
                    height: .17rem;
                }
                .good-num{
                    margin: 0rem .06rem;
                }
                :last-child{
                    font-weight: 400;
                    font-size: .15rem;
                    color: #8594A2;
                    margin-left: .06rem;
                }
            }
            .site{
                height: .25rem;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: .15rem;
                color: #8594A2;
                img{
                    width: .23rem;
                    height: .23rem;
                }
                :nth-child(2){
                    margin: 0rem .06rem;
                }
                :last-child{
                    margin-left: .06rem;
                }
            }
            .workDate{
                margin: .1rem 0rem .24rem;
                height: .25rem;
                display: flex;
                align-items: center;
                font-weight: 500;
                font-size: .15rem;
                color: #8594A2;
                img{
                    width: .23rem;
                    height: .23rem;
                }
            }
        }
    }
    .materials-title{
        margin: .63rem 0rem .13rem;
        :nth-child(1){
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-right: .1rem;
        }
        :last-child{
            margin-left: .13rem;
            font-weight: 400;
            font-size: .13rem;
            color: #09A966;
        }
    }
    .materials{
        width: 14.5rem;
        height: 3.66rem;
        border: .01rem solid #D7DBE6;
        display: flex;
        padding: .25rem .3rem;
        box-sizing: border-box;
        .item{
            cursor: pointer;
            width: 2.5rem;
            height: 3.16rem;
            background: #FFFFFF;
            border-radius: 0rem 0rem 0rem 0rem;
            border: .01rem solid #F5F5F5;
            display: flex;
            flex-direction: column;
            padding: .13rem;
            box-sizing: border-box;
            margin-right: .13rem;
            .img{
                width: 2.25rem;
                height: 2.13rem;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .name{
                margin: .13rem 0rem .1rem;
                font-weight: 600;
                font-size: .17rem;
                color: #1D1F23;
            }
            .item-price{
                padding-top: .1rem;
                box-sizing: border-box;
                border-top: .01rem solid #F5F5F5;
                display: flex;
                align-items: center;
                font-weight: 400;
                font-size: .13rem;
                color: #8594A2;
                .price{
                    :nth-child(1){
                        font-weight: bold;
                        font-size: .15rem;
                        color: #E75763;
                    }
                    :nth-child(2){
                        font-weight: bold;
                        font-size: .2rem;
                        color: #E75763;
                    }
                    :nth-child(3){
                        font-weight: 400;
                        font-size: .1rem;
                        color: #E75763;
                    }
                }
            }
        }
        .active{
            border: .01rem solid #09A966;
        }
    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        font-weight: 500;
        font-size: .17rem;
        .submit{
            width: 1.02rem;
            height: .5rem;
            color: #788086;
            font-weight: 500;
            font-size: .17rem;
            color: #FFFFFF;
            text-align: center;
            line-height: .5rem;
            background: #09A966;
        }
    }
}
</style>