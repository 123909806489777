<template>
    <div class="demand">
        <div class="demandMark">
            <div class="title">
                <span>产权登记</span>
                <img src="../../assets/wrong.png" alt="" @click="closeAlert">
            </div>
            <succe v-if="$store.getters.equityStatus"></succe>
        </div>
    </div>
</template>

<script>
import succe from './sucess.vue';
    export default {
        components: {
            succe
        },
        methods: {
            closeAlert() {
                this.$store.commit("setequity",false)
            },
            changeActive() {
                if(this.$store.getters.demAct < 5){
                    this.$store.commit("demAdd")
                }else{
                    this.closeAlert()
                }
            },
            demMin(){
                this.$store.commit("demMines")
            }
        }
    }
</script>

<style lang="scss" scoped>
.demand{
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    .demandMark{
        box-sizing: border-box;
        position: fixed;
        top: 1.31rem;
        left: 3.25rem;
        height: 7.5rem;
        overflow-x: auto;
        padding: 0rem 1.5rem;
        box-sizing: border-box;
        background: linear-gradient( 180deg, #F6FFFB 0%, #FFFFFF 100%);
        .title{
            margin: .75rem 0rem .56rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            span{
                font-weight: 500;
                font-size: .25rem;
                color: #1D1F23;
            }
            img{
                cursor: pointer;
                width: .35rem;
                height: .35rem;
            }
        }
    }
}
</style>