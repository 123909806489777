<template>
    <div class="automobileOne">
        <div class="auto-info">
            <div class="auto-info-one">
                <div class="auto-info-left">
                    <div class="auto-info-title">模型名称</div>
                    <div class="auto-info-content">
                        <el-input placeholder="请输入模型名称" v-model="modelName" clearable></el-input>
                    </div>
                </div>
                <div class="auto-info-right">
                    <div class="auto-info-title">模型名称</div>
                    <div class="auto-info-content">
                        <el-select v-model="value" placeholder="请选择分类">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="auto-info-two">
                <div class="auto-info-left">
                    <div class="auto-info-title">模型来源</div>
                    <div class="auto-info-content">
                        <el-radio v-model="modelSource" label="1">上传模型</el-radio>
                        <el-radio v-model="modelSource" label="2">线下诊断</el-radio>
                    </div>
                </div>
                <div class="auto-info-right">
                    <div class="auto-info-title">模型标签</div>
                    <div class="auto-info-content">
                        <el-input placeholder="请输入多个标签 使用'、'分割" v-model="modelName" clearable></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="ray">
            <div class="ray-title">
                <span>上传模型</span>
                <span>请上传JPG、JPEG、PNG、PDF、WOD格式文件</span>
            </div>
            <div class="ray-box">

            </div>
        </div>
        <div class="examine">
            <div class="examine-title">
                <span>在线检查</span>
                <el-switch v-model="examine" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </div>
            <div class="examine-box" v-if="examine">
                <div class="examine-box-left">
                    <div class="examine-left-title">
                        <span>匹配医生</span>
                        <img src="../../assets/wrong.png" alt="">
                    </div>
                    <div class="img">
                        <img :src="doctorInfo.img" alt="">
                    </div>
                    <div class="size">{{doctorInfo.post}}<span class="border">|</span><span>{{doctorInfo.date}}</span></div>
                    <div class="name">{{doctorInfo.name}}</div>
                    <div class="btn">立即接通</div>
                </div>
                <div class="examine-box-right">
                    <div class="examine-right-title">医生诊断建议</div>
                    <div class="examine-right-box"></div>
                </div>
            </div>
        </div>
        <div class="bottom-btn">
            <div class="cancel" @click="close">取消</div>
            <div class="submit" @click="autoAdd">提交</div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                modelName:"",
                options: [
                    {
                        value: '选项1',
                        label: '黄金糕'
                    },
                    {
                        value: '选项2',
                        label: '双皮奶'
                    },
                ],
                value: '',
                modelSource: '1',  //模型来源
                examine: true,
                doctorInfo:{
                    img:require("../../assets/dentistry/listOne.png"),
                    date:"10年临床经验",
                    post:"辉煌牙科医院主治大夫",
                    name:"安宏"
                },

            }
        },
        methods:{
            autoAdd(){
                this.$emit('addAct')
            },
            close(){
                this.$emit('closeAlt')
            }
        }
    }
</script>

<style lang="scss" scoped>
.automobileOne{
    .auto-info{
        .auto-info-title{
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
        }
        .auto-info-one{
            height: .81rem;
            display: flex;
            justify-content: space-between;
            margin: .38rem 0rem;
            .auto-info-left{
                width: 6.63rem;   
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
            .auto-info-right{
                width: 6.63rem;
                display: flex;
                justify-content: space-between;
                flex-direction: column;   
                .auto-info-content{
                    ::v-deep{
                        .el-input__inner{
                            width: 6.63rem;
                        }
                    }
                } 
            }
        }
        .auto-info-two{
            height: .81rem;
            display: flex;
            justify-content: space-between;
            .auto-info-left{
                width: 6.63rem;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
            .auto-info-right{
                width: 6.63rem;   
                display: flex;
                justify-content: space-around;
                flex-direction: column;
            }
        }
    }
    .ray{
        margin-top: .38rem;
        .ray-title{
            margin-bottom: .15rem;
            :nth-child(1){
                font-weight: 600;
                font-size: .15rem;
                color: #1D1F23;
                margin-right: .1rem;
            }
            :last-child{
                margin-left: .13rem;
                font-weight: 400;
                font-size: .13rem;
                color: #8594A2;
            }
        }
        .ray-box{
            width: 14.5rem;
            height: 1.68rem;
            border-radius: 0rem 0rem 0rem 0rem;
            border: .01rem solid #D7DBE6;
        }
    }
    .examine{
        margin-top: .38rem;
        .examine-title{
            margin-bottom: .15rem;
            :nth-child(1){
                font-weight: 600;
                font-size: .15rem;
                color: #1D1F23;
                margin-right: .1rem;
            }
        }
        .examine-box{
            width: 14.5rem;
            height: 6.08rem;
            border-radius: 0rem 0rem 0rem 0rem;
            border: .01rem solid #D7DBE6;
            display: flex;
            .examine-box-left{
                margin:.25rem .5rem 0rem .25rem;
                width: 3.25rem;
                display: flex;
                flex-direction: column;
                .examine-left-title{
                    height: .25rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    span{
                        font-weight: 400;
                        font-size: .15rem;
                        color: #1D1F23;
                    }
                    img{
                        width: .15rem;
                        height: .15rem;
                    }
                }
                .img{
                    margin-top: .13rem;
                    width: 3.25rem;
                    height: 3.75rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .size{
                    .border{
                        margin: 0rem .06rem;
                    }
                    :last-child{
                        color: rgba(9, 169, 102, 1);
                    }
                    height: .46rem;
                    line-height: .46rem;
                    font-weight: 400;
                    font-size: .15rem;
                    color: #8594A2;
                    border-bottom: .01rem solid #F5F5F5;
                }
                .name{
                    margin-top: .1rem;
                    font-weight: 600;
                    font-size: .2rem;
                    color: #1D1F23;
                }
                .btn{
                    margin-left: calc(50% - .61rem);
                    margin-top: .28rem;
                    width: 1.23rem;
                    height: .4rem;
                    line-height: .4rem;
                    text-align: center;
                    background: #09A966;
                    font-weight: 400;
                    font-size: .17rem;
                    color: #FFFFFF;
                }
            }
            .examine-box-right{
                width: 10.13rem;
                .examine-right-title{
                    font-weight: 400;
                    font-size: .15rem;
                    color: #1D1F23;
                    height: .25rem;
                    margin: .25rem 0rem .13rem;
                }
                .examine-right-box{
                    width: 10.13rem;
                    height: 5.24rem;
                    border-radius: 0rem 0rem 0rem 0rem;
                    border: .01rem solid #D7DBE6;
                }
            }
        }
    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        .cancel,.submit{
            width: .85rem;
            height: .5rem;
            line-height: .5rem;
            text-align: center;
            font-weight: 500;
            font-size: .17rem;
        }
        .cancel{
            background: #FFFFFF;
            color: #09A966;
            border: .01rem solid #09A966;
        }
        .submit{
            margin-left: .25rem;
            background: #09A966;
            color: #FFFFFF;
            border: .01rem solid #09A966;
        }
    }
}
</style>