import request from '@/utils/request.js';

//获取收藏列表
export function getCollect(params) {
    return request({
        url: "api/goods/myCollect",
        method: 'get',
        params,
    });
}

//获取关注列表
export function getFollow(params) {
    return request({
        url: "api/service/myFollow",
        method: 'get',
        params,
    });
}

//获取需求列表
export function getDemand(params) {
    return request({
        url: "api/demand/index",
        method: 'get',
        params,
    });
}

// 删除需求
export function delDem(params) {
    return request({
        url: "api/demand/delete",
        method: 'get',
        params,
    });
}

// 下单
export function payDem(params) {
    return request({
        url: "api/demand/order",
        method: 'get',
        params,
    });
}

// 需求详情
export function showDemand(params) {
    return request({
        url: "api/demand/find",
        method: 'get',
        params,
    });
}

//修改需求
export function fixDemand(data) {
    return request({
        url: "api/demand/edit",
        method: 'post',
        data,
    });
}