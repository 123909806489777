import request from '@/utils/request.js';

// 获取短信验证码
export function getSite(params) {
    return request({
        url: "api/system.city/index",
        method: 'get',
        params,
    });
}

// 获取服务商列表
export function getFac(params) {
    return request({
        url: "api/service/index",
        method: 'get',
        params,
    });
}

// 服务商详情
export function FacDel(params) {
    return request({
        url: "api/service/find",
        method: 'get',
        params,
    });
}

// 关注
export function attention(params) {
    return request({
        url: "api/service/follow",
        method: 'get',
        params,
    });
}
