const demand = {
    state: {
        serviceItem:{},
        shopId:0,
        demAct:1,
        material:[],
        materialItem:{},
        product:[],
        productItem:{},
        info:{
            
        }
    },

    mutations: {
        demAdd:(state)=>{
            state.demAct = state.demAct += 1;
            // console.log(state.demAct,"demAct")
        },
        demMines:(state)=>{
            state.demAct = state.demAct -= 1;
        },
        setDem(state,obj){
            state.info[obj.title] = obj.val
        },
        clearDem(){
            state.info = {}
        },
        setShopId(state,val){
            state.shopId = val
        },
        setMat(state,val){
            state.material = val
        },
        setPro(state,val){
            state.product = val
        },
        setMatId(state,val){
            state.materialItem = val
        },
        setProId(state,val){
            state.productItem = val
        },
        setItem(state,val){
            state.serviceItem = val
        },
        closeDem(state){
            state.info = {}
            state.demAct = 1
        }
    }
}

export default demand
