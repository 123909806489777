import request from '@/utils/request.js';

// 获取首页列表
export function getData(params) {
    return request({
        url: "api/goods/index",
        method: 'get',
        params,
    });
}

// 获取首页详情
export function getDataDet(params) {
    return request({
        url: "api/goods/find",
        method: 'get',
        params,
    });
}

// 收藏
export function collect(params) {
    return request({
        url: "api/goods/collect",
        method: 'get',
        params,
    });
}

// 新增需求
export function addDemand(data) {
    return request({
        url: "api/demand/add",
        method: 'post',
        data,
    });
}

// 获取分类
export function getType(params) {
    return request({
        url: "api/category/getCategorysById",
        method: 'get',
        params,
    });
}
