<template>
    <div class="dentistry-two">
        <div class="examine">
            <div class="examine-box">
                <div class="examine-box-left">
                    <div class="examine-left-title">主治医生</div>
                    <div class="img">
                        <img :src="doctorInfo.img" alt="">
                    </div>
                    <div class="size">{{doctorInfo.post}}<span class="border">|</span><span>{{doctorInfo.date}}</span></div>
                    <div class="name">{{doctorInfo.name}}</div>
                </div>
                <div class="examine-box-right">
                    <div class="examine-right-title">评估结果</div>
                    <div class="examine-right-box"></div>
                </div>
            </div>
        </div>
        <div class="bottom-btn">
            <div class="submit" @click="denAdd">下一步</div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return{
                doctorInfo:{
                    img:require("../../assets/dentistry/listOne.png"),
                    date:"10年临床经验",
                    post:"辉煌牙科医院主治大夫",
                    name:"安宏"
                }
            }
        },
        methods:{
            denAdd(){
                this.$emit('addAct')
            }
        }
    }
</script>

<style lang="scss" scoped>
.dentistry-two{
    .examine{
        margin-top: .38rem;
        .examine-title{
            margin-bottom: .15rem;
            :nth-child(1){
                font-weight: 600;
                font-size: .15rem;
                color: #1D1F23;
                margin-right: .1rem;
            }
        }
        .examine-box{
            width: 14.5rem;
            height: 6.08rem;
            border-radius: 0rem 0rem 0rem 0rem;
            display: flex;
            .examine-box-left{
                margin:.25rem .5rem 0rem .25rem;
                width: 3.25rem;
                display: flex;
                flex-direction: column;
                .examine-left-title{
                    font-weight: 600;
                    font-size: .15rem;
                    color: #1D1F23;
                }
                .img{
                    margin-top: .13rem;
                    width: 3.25rem;
                    height: 3.75rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .size{
                    .border{
                        margin: 0rem .06rem;
                    }
                    :last-child{
                        color: rgba(9, 169, 102, 1);
                    }
                    height: .46rem;
                    line-height: .46rem;
                    font-weight: 400;
                    font-size: .15rem;
                    color: #8594A2;
                    border-bottom: .01rem solid #F5F5F5;
                }
                .name{
                    margin-top: .1rem;
                    font-weight: 600;
                    font-size: .2rem;
                    color: #1D1F23;
                }
                .btn{
                    margin-left: calc(50% - .61rem);
                    margin-top: .28rem;
                    width: 1.23rem;
                    height: .4rem;
                    line-height: .4rem;
                    text-align: center;
                    background: #09A966;
                    font-weight: 400;
                    font-size: .17rem;
                    color: #FFFFFF;
                }
            }
            .examine-box-right{
                width: 10.13rem;
                .examine-right-title{
                    font-weight: 600;
                    font-size: .15rem;
                    color: #1D1F23;
                    height: .25rem;
                    margin: .25rem 0rem .13rem;
                }
                .examine-right-box{
                    width: 10.88rem;
                    height: 4.59rem;
                    border-radius: 0rem 0rem 0rem 0rem;
                    border: .01rem solid #D7DBE6;
                }
            }
        }
    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        .cancel,.submit{
            width: .85rem;
            height: .5rem;
            line-height: .5rem;
            text-align: center;
            font-weight: 500;
            font-size: .17rem;
        }
        .submit{
            margin-left: .25rem;
            background: #09A966;
            color: #FFFFFF;
            border: .01rem solid #09A966;
        }
    }
}
</style>