<template>
    <div class="draw">
        <div class="draw-box">
            <div class="draw-title">
                <div class="title-nav">
                    <div class="item" v-for="(item,index) in navList" :key="index">
                        <img :src="item.icon" alt="">
                        <span>{{item.name}}</span>
                    </div>
                </div>
                <img src="../../assets/wrong.png" class="wrong" alt="" @click="close">
            </div>
            <div class="draw-content">
                <div class="left">
                    <div class="select">
                        <div class="one">
                            <span>打印工艺</span>
                            <el-select v-model="value" placeholder="请选择工艺">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="one">
                            <span>打印材质</span>
                            <el-select v-model="value" placeholder="请选择材质">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="fenxi">
                        <div class="fenxi-one">
                            <span :class="index == 1 ? 'active' : ''">位移分析</span>
                            <span :class="index == 2 ? 'active' : ''">应力分析</span>
                            <span :class="index == 3 ? 'active' : ''">位变分析</span>
                        </div>
                        <div class="fenxi-two">
                            <span>编辑测试标记</span>
                            <el-input v-model="input" placeholder="请输入内容"></el-input>
                        </div>
                        <div class="fenxi-three">
                            添加新的标记点
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div class="ceshi">
                        <div class="select">
                            <div class="one">
                                <div class="fenxi-one">
                                    <span :class="index == 1 ? 'active' : ''">跌落测试</span>
                                    <span :class="index == 2 ? 'active' : ''">碰撞测试</span>
                                </div>
                                <span class="span">打印工艺</span>
                                <div class="jisuan">
                                    <div class="jisuan-left">
                                        <div class="jian">-</div>
                                        <div class="numb">{{numb}}</div>
                                        <div class="jia">+</div>
                                    </div>
                                    <span class="danwei">cm</span>
                                </div>
                            </div>
                            <div class="one">
                                <span class="span">地面材质</span>
                                <el-select v-model="value" placeholder="请选择材质">
                                    <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>
                    </div>
                    <div class="upload">
                        <span class="title">装配模型</span>
                        <el-upload
                        class="upload-demo"
                        action="https://jsonplaceholder.typicode.com/posts/"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        list-type="picture">
                            <div slot="tip" class="el-upload__tip">请上传STL、OBJ、AMF、3MF、STEP格式文件</div>
                            <el-button size="small" type="primary">点击上传</el-button>
                        </el-upload>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'draw',
        data(){
            return{
                navList:[
                    {
                        name:"上传",
                        icon:require("../../assets/model/1.png")
                    },
                    {
                        name:"保存",
                        icon:require("../../assets/model/2.png")
                    },
                    {
                        name:"移动",
                        icon:require("../../assets/model/3.png")
                    },
                    {
                        name:"旋转",
                        icon:require("../../assets/model/4.png")
                    },
                    {
                        name:"比例",
                        icon:require("../../assets/model/5.png")
                    },
                    {
                        name:"参考线",
                        icon:require("../../assets/model/6.png")
                    },
                    {
                        name:"文本",
                        icon:require("../../assets/model/7.png")
                    },
                    {
                        name:"删除",
                        icon:require("../../assets/model/8.png")
                    },
                    {
                        name:"三角形",
                        icon:require("../../assets/model/9.png")
                    },
                    {
                        name:"圆顶",
                        icon:require("../../assets/model/10.png")
                    },
                    {
                        name:"颜料桶",
                        icon:require("../../assets/model/11.png")
                    },
                    {
                        name:"观察",
                        icon:require("../../assets/model/12.png")
                    },
                    {
                        name:"平移",
                        icon:require("../../assets/model/13.png")
                    },
                    {
                        name:"缩小",
                        icon:require("../../assets/model/14.png")
                    },
                    {
                        name:"放大",
                        icon:require("../../assets/model/15.png")
                    },
                ],
                options: [
                    {
                        value: '选项1',
                        label: '黄金糕'
                    },
                    {
                        value: '选项2',
                        label: '双皮奶'
                    },{
                        value: '选项3',
                        label: '蚵仔煎'
                    },
                ],
                fileList: [
                    {name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'},
                ],
                index: 1,
                input:"",
                numb:5,
            }
        },
        methods:{
            close(){
                this.$store.commit("setDrawFlag",false)
            }
        }
    }
</script>

<style lang="scss" scoped>
.draw{
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
    .draw-box{
        box-sizing: border-box;
        position: fixed;
        top: 1.31rem;
        left: 3.25rem;
        height: 7.5rem;
        overflow-x: auto;
        // padding: 0rem 1.5rem;
        // box-sizing: border-box;
        background: linear-gradient( 180deg, #F6FFFB 0%, #FFFFFF 100%);
        .draw-title{
            position: relative;
            // margin: .75rem 0rem .56rem;
            width: 1400px;
            display: flex;
            justify-content: center;
            .title-nav{
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                .item{
                    margin-right: 24px;
                    display: flex;
                    flex-direction: column;
                    img{
                        width: 20px;
                        height: 20px;
                    }
                    span{
                        font-weight: 500;
                        font-size: 10px;
                        color: #1D1F23;
                    }
                }
            }
            .wrong{
                width: 28px;
                height: 28px;
                position: absolute;
                top: 16px;
                right: 50px;
            }
        }
        .draw-content{
            width: 100%;
            height: 500px;
            display: flex;
            justify-content: space-between;
            // background: pink;
            background: linear-gradient( 23deg, rgba(255,255,255,0) 0%, #F5FDFF 48%, #EFFCFF 100%);
            .left{
                .select{
                    width: 250px;
                    height: 182px;
                    background: #FFFFFF;
                    box-shadow: 3px 0px 6px 0px rgba(149,149,149,0.07);
                    display: flex;
                    flex-direction: column;
                    .one{
                        padding: 0px 15px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        span{
                            font-weight: 500;
                            font-size: 12px;
                            color: #1D1F23;
                            margin: 20px 0px 15px;
                        }
                        ::v-deep{
                            .el-input--suffix .el-input__inner{
                                height: 30px;
                            }
                        }
                    }
                }
                .fenxi{
                    margin-top: 40px;
                    width: 250px;
                    height: 192px;
                    background: #FFFFFF;
                    padding: 20px 15px 0px 15px;
                    box-sizing: border-box;
                    box-shadow: 3px 0px 6px 0px rgba(149,149,149,0.07);
                    .fenxi-one{
                        display: flex;
                        justify-content: space-around;
                        // width: 222px;
                        // height: 26px;
                        background: #EFEFEF;
                        span{
                            padding: 5px 10px;
                            box-sizing: border-box;
                            font-weight: 400;
                            font-size: 12px;
                            color: #1D1F23;
                        }
                        .active{
                            background: #09A966;
                            font-weight: 500;
                            font-size: 12px;
                            color: #FFFFFF;
                        }
                    }
                    .fenxi-two{
                        span{
                            display: block;
                            font-weight: 500;
                            font-size: 12px;
                            color: #1D1F23;
                            margin: 20px 0px 10px;
                        }
                        ::v-deep{
                            .el-input__inner{
                                height: 30px;
                            }
                        }
                    }
                    .fenxi-three{
                        margin-top: 20px;
                        background: #09A966;
                        width: 100%;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        font-weight: 500;
                        font-size: 10px;
                        color: #FFFFFF;
                    }
                }
            }
            .right{
                .ceshi{
                    .select{
                        width: 250px;
                        height: 182px;
                        background: #FFFFFF;
                        box-shadow: 3px 0px 6px 0px rgba(149,149,149,0.07);
                        display: flex;
                        flex-direction: column;
                        .fenxi-one{
                            display: flex;
                            justify-content: space-around;
                            // width: 222px;
                            // height: 30px;
                            background: #EFEFEF;
                            span{
                                padding: 5px 30px;
                                box-sizing: border-box;
                                font-weight: 400;
                                font-size: 12px;
                                color: #1D1F23;
                            }
                            .active{
                                background: #09A966;
                                font-weight: 500;
                                font-size: 12px;
                                color: #FFFFFF;
                            }
                        }
                        .one{
                            padding: 0px 15px;
                            box-sizing: border-box;
                            display: flex;
                            flex-direction: column;
                            .span{
                                font-weight: 500;
                                font-size: 12px;
                                color: #1D1F23;
                                margin: 20px 0px 10px;
                            }
                            ::v-deep{
                                .el-input--suffix .el-input__inner{
                                    height: 30px;
                                }
                            }
                            .jisuan{
                                display: flex;
                                .jisuan-left{
                                    border: 1px solid #D7DBE6;
                                    display: flex;
                                    .jian{
                                        width: 42px;
                                        height: 30px;
                                        text-align: center;
                                        line-height: 30px;
                                    }
                                    .numb{
                                        width: 86px;
                                        height: 30px;
                                        text-align: center;
                                        line-height: 30px;
                                        border-left: 1px solid #D7DBE6;
                                        border-right: 1px solid #D7DBE6;
                                    }
                                    .jia{
                                        width: 42px;
                                        height: 30px;
                                        text-align: center;
                                        line-height: 30px;
                                    }
                                }
                                .danwei{
                                    margin-left: 16px;
                                    font-weight: 500;
                                    font-size: 16px;
                                    color: #1D1F23;
                                }
                            }
                        }
                    }
                }
                .upload{
                    padding: 20px 15px 0px 15px;
                    box-sizing: border-box;
                    margin-top: 40px;
                    width: 250px;
                    height: 237px;
                    background: #FFFFFF;
                    box-shadow: 3px 0px 6px 0px rgba(149,149,149,0.07);
                    .title{
                        display: block;
                        font-weight: 500;
                        font-size: 12px;
                        color: #1D1F23;
                        margin-bottom: 5px;
                    }
                }
            }
        }
        
    }
}
</style>