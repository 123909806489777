<template>
    <div class="step">
        <el-steps :active="$store.getters.denAct" simple>
            <el-step title="病情诊断"></el-step>
            <el-step title="病情评估"></el-step>
            <el-step title="预约诊所"></el-step>
            <el-step title="模型采集"></el-step>
            <el-step title="选择材质"></el-step>
            <el-step title="手术报告"></el-step>
        </el-steps>
    </div>
</template>

<script>
    export default {
        name: 'DemandTitle',
    }
</script>

<style lang="scss" scoped>
.step{
    width: 14.5rem;
}
</style>