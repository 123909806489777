<template>
    <div class="demandFour">
        <div class="expressageType">
            <div class="type-title">物流方式</div>
            <div class="type-content">
                <div class="demand-info-content">
                    <el-radio v-model="modelSource" label="1">快递公司</el-radio>
                    <el-radio v-model="modelSource" label="2" disabled>线下自提</el-radio>
                </div>
            </div>
        </div>
        <div class="expressageSite">
            <div class="site-title">物流方式</div>
            <div class="site-content">
                <div class="site-content-left"><span>{{site.province}}</span><span>{{site.city}}</span><span>{{site.address}}</span></div>
                <div class="site-content-right">
                    <img src="../../assets/detail/site.png" alt="">
                    <span>距离我{{site.distance}}</span>
                </div>
            </div>
        </div>
        <div class="siteList">
            <div class="siteList-title">邮寄地址</div>
            <div class="siteList-content">
                <div v-for="(item,index) in siteList" :key="index" @click="cutSite(index)" :class="siteAct == index ? 'item active' : 'item'">
                    <div class="site-one">
                        <span>{{item.province}}</span><span>{{item.city}}</span><span>({{item.name}}收)</span>
                    </div>
                    <div class="site-two"><span>{{item.address}}</span><span>{{item.phone}}</span></div>
                    <div class="site-three" v-if="index == siteAct">
                        <span>修改</span>
                    </div>
                    <div class="default" v-if="item.is_default == 1">默认地址</div>
                    <div class="check" v-if="index == siteAct">
                        <img src="../../assets/detail/siteCheck.png" alt="">
                    </div>
                    <div class="site-bot">
                        <img src="../../assets/detail/check.png" alt="" v-if="index == siteAct">
                        <img src="../../assets/detail/checkNot.png" alt="" v-else>
                    </div>
                </div>
            </div>
            <div class="siteList-bot">
                <span>显示全部地址</span>
                <span>管理收获地址</span>
            </div>
        </div>
        <div class="express">
            <span>选择快递公司</span>
            <span>根据收发货地址信息，有以下快递公司可供选择</span>
        </div>
        <div class="expressContent">
            <el-select v-model="value" placeholder="请选择分类">
                <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
        </div>
        <div class="bottom-btn">
            <div class="save" @click="saveDem">保存模型</div>
            <div class="submit" @click="demAdd">下一步</div>
        </div>
    </div>
</template>

<script>
import { addModel , fixModel } from "../../api/layout/model"
    export default {
        data(){
            return {
                modelSource: '1',
                site:{
                    name:"韩先森",
                    province:"北京市",
                    city:"北京市",
                    address:"北京市朝阳区",
                    distance:"100km"
                },
                siteList:[
                    {
                        name:"韩先森",
                        phone:"13388888888",
                        province:"河北省",
                        city:"邯郸市",
                        address:"丛台区 苏曹乡 滏河北大街580号工业设计中心",
                        distance:"100km",
                        is_default:1,
                    },
                    {
                        name:"刘典",
                        phone:"13388888888",
                        province:"河北省",
                        city:"邯郸市",
                        address:"丛台区 苏曹乡 滏河北大街580号工业设计中心",
                        distance:"100km",
                        is_default:0,
                    },
                    {
                        name:"刘典",
                        phone:"13388888888",
                        province:"河北省",
                        city:"邯郸市",
                        address:"丛台区 苏曹乡 滏河北大街580号工业设计中心",
                        distance:"100km",
                        is_default:0,
                    },
                    {
                        name:"刘典",
                        phone:"13388888888",
                        province:"河北省",
                        city:"邯郸市",
                        address:"丛台区 苏曹乡 滏河北大街580号工业设计中心",
                        distance:"100km",
                        is_default:0,
                    },
                    {
                        name:"刘典",
                        phone:"13388888888",
                        province:"河北省",
                        city:"邯郸市",
                        address:"丛台区 苏曹乡 滏河北大街580号工业设计中心",
                        distance:"100km",
                        is_default:0,
                    },
                    {
                        name:"刘典",
                        phone:"13388888888",
                        province:"河北省",
                        city:"邯郸市",
                        address:"丛台区 苏曹乡 滏河北大街580号工业设计中心",
                        distance:"100km",
                        is_default:0,
                    },
                ],
                siteAct:0,
                options: [
                    {
                        value: '顺丰',
                        label: '顺丰快递'
                    },
                    {
                        value: '邮政',
                        label: '邮政快递'
                    },
                    {
                        value: '圆通',
                        label: '圆通快递'
                    },
                    {
                        value: '极兔',
                        label: '极兔快递'
                    },
                ],
                value: '',
            }
        },
        methods:{
            cutSite(val){
                this.siteAct = val;
            },
            demAdd(){
                console.log(this.value);
                this.$store.commit("setDem",{title:'express_type',val:this.modelSource})
                this.$store.commit("setDem",{title:'express_addre',val:this.siteList[this.siteAct].address})
                this.$store.commit("setDem",{title:'express_comp',val:this.value})

                this.$emit('addAct')
            },
            saveDem(){
                console.log("1");
                if(this.$store.getters.info["status"] == "fix"){
                    console.log("2");
                    this.fixModel()
                }else{
                    this.submit()
                }
            },
            // 修改模型
            fixModel(){
                let params = {
                    id:this.$store.getters.info["demand-id"],
                    name:this.$store.getters.info["model-name"],
                    category_id:this.$store.getters.info["demand-type"],
                    source:this.$store.getters.info["model-source"],
                    label:this.$store.getters.info["demand-label"],
                    file: this.$store.getters.info["demand-img"],
                    image: this.$store.getters.info["demand-img"],
                    size: "24*8*2mm",
                    file_size: "122m",
                    volume:"384mm",
                    area: "500mm"
                }
                console.log(params,"999999999");
                fixModel(params).then(res=>{
                    console.log(res.data.id,"res.data.id");
                    if(res.code == 1 && val == 1){
                        this.$store.commit('closeDemFlag')
                    }else{
                        this.$store.commit("setDem",{title:'demand-id',val:res.data.id})
                        // console.log();
                        this.denAdd()
                    }
                }).catch(err=>{
                    console.log(err);
                })
            },
            // 上传模型
            submit(){
                
                if(this.$store.getters.info["demand-id"]){
                    this.$message({
                        message: '请勿重复添加！',
                        type: 'warning'
                    });
                    return
                }
                
                let params={
                    name:this.$store.getters.info["model-name"],
                    category_id:this.$store.getters.info["demand-type"],
                    source:this.$store.getters.info["model-source"],
                    label:this.$store.getters.info["demand-label"],
                    file: this.$store.getters.info["demand-img"],
                    image: this.$store.getters.info["demand-img"],
                    size: "24*8*2mm",
                    file_size: "122m",
                    volume:"384mm",
                    area: "500mm"
                }
                addModel(params).then(res=>{
                    this.$message({
                        message: `${res.msg}`,
                        type: 'success'
                    });
                    console.log(res.data.id,"res.data.id");
                    this.$store.commit("setDem",{title:'demand-id',val:res.data.id})
                    // this.denAdd()
                    // if(res.code == 1 && val == 1){
                    //     this.$store.commit('closeDemFlag')
                    // }else{
                    //     this.$store.commit("setDem",{title:'demand-id',val:res.data.id})
                    //     // console.log();
                    //     this.denAdd()
                    // }
                }).catch(err=>{
                    console.log(err);
                })
            },
        },
        mounted(){
            if(this.$store.getters.info["express_comp"]){
                this.value = this.$store.getters.info["express_comp"]
            }
        }
    }
</script>

<style lang="scss" scoped>
.demandFour{
    .expressageType{
        width: 14.5rem;
        display: flex;
        // width: 100%;
        margin-bottom: .38rem;
        .type-title{
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-right: .38rem;
        }
    }
    .expressageSite{
        .site-title{
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-bottom: .13rem;
        }
        .site-content{
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: .15rem;
            color: #788086;
            display: flex;
            align-items: center;
            .site-content-left{
                span{
                    margin-right: .06rem;
                }
            }
            .site-content-right{
                display: flex;
                align-items: center;
                img{
                    width: .23rem;
                    height: .23rem;
                }
            }
        }
    }
    .siteList{
        .siteList-title{
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin: .38rem 0rem .13rem;
        }
        .siteList-content{
            display: flex;
            .item{
                cursor: pointer;
                padding: 0rem .13rem;
                box-sizing: border-box;
                position: relative;
                width: 2.27rem;
                height: 1.05rem;
                background: #FFFFFF;
                border: .01rem solid #D7DBE6;
                margin-right: .16rem;
                .site-one{
                    height: .33rem;
                    padding: .07rem 0rem;
                    box-sizing: border-box;
                    font-weight: 500;
                    font-size: .13rem;
                    color: #1D1F23;
                    border-bottom: .01rem solid #D7DBE6;
                }
                .site-two{
                    font-weight: 400;
                    font-size: .11rem;
                    color: #666666;
                    line-height: .14rem;
                    margin: .07rem 0rem .05rem;
                }
                .site-three{
                    font-weight: 300;
                    font-size: .11rem;
                    color: #09A966;
                }
                .default{
                    position: absolute;
                    top: 0rem;
                    right: 0rem;
                    width: .45rem;
                    height: .19rem;
                    line-height: .19rem;
                    text-align: center;
                    background: #1D1F23;
                    opacity: 0.5;
                    font-weight: 400;
                    font-size: .1rem;
                    color: #FFFFFF;
                }
                .check{
                    z-index: 10;
                    width: .28rem;
                    height: .28rem;
                    position: absolute;
                    bottom: 0rem;
                    right: 0rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .site-bot{
                    img{
                        width: 100%;
                        height: .04rem;
                        position: absolute;
                        bottom: 0rem;
                        left: 0rem;
                        right: 0rem;
                    }
                }
            }
            .active{
                border: .01rem solid #09A966;
            }
        }
        .siteList-content :last-child{
            margin-right: 0rem;
        }
        .siteList-bot{
            margin: .2rem 0rem .38rem;
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: .13rem;
            color: #09A966;
        }
    }
    .express{
        height: .21rem;
        display: flex;
        align-items: center;
        margin-bottom: .1rem;
        :nth-child(1){
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-right: .1rem;
        }
        :last-child{
            font-weight: 400;
            font-size: .13rem;
            color: #09A966;
        }
    }
    .expressContent{
        ::v-deep{
            .el-input__inner{
                width: 6.63rem;
                height: .5rem;
            }
        }
    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        font-weight: 500;
        font-size: .17rem;
        .save{
            margin-right: .25rem;
            width: 1.2rem;
            height: .5rem;
            text-align: center;
            line-height: .5rem;
            background: #FFFFFF;
            border-radius: 0rem 0rem 0rem 0rem;
            border: .01rem solid #09A966;
            color: #09A966;
        }
        .submit{
            width: 1.02rem;
            height: .5rem;
            text-align: center;
            color: #FFFFFF;
            line-height: .5rem;
            background: #09A966;
        }
    }
}
</style>