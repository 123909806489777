<template>
    <div class="step">
        <el-steps :active="$store.getters.autoAct" simple>
            <el-step title="上传模型"></el-step>
            <el-step title="模型优化"></el-step>
            <el-step title="选择打印机"></el-step>
            <el-step title="选择耗材"></el-step>
            <el-step title="表面处理"></el-step>
            <el-step title="产品测试"></el-step>
        </el-steps>
    </div>
</template>

<script>
    export default {
        name: 'AutoTitle',
    }
</script>

<style lang="scss" scoped>
.step{
    width: 14.5rem;
}
</style>