<template>
    <div class="demandOne">
        <div class="demand-info">
            <div class="demand-info-one">
                <div class="demand-info-title">需求名称</div>
                <div class="demand-info-content">
                    <el-input placeholder="请输入内容" v-model="demandName" clearable></el-input>
                </div>
            </div>
            <div class="demand-info-two">
                <div class="demand-info-left">
                    <div class="demand-info-title">模型名称</div>
                    <div class="demand-info-content">
                        <el-input placeholder="请输入模型名称" v-model="modelName" clearable></el-input>
                    </div>
                </div>
                <div class="demand-info-right">
                    <div class="demand-info-title">模型来源</div>
                    <div class="demand-info-content">
                        <el-radio v-model="modelSource" label="1">上传模型</el-radio>
                        <el-radio v-model="modelSource" label="2">在线制图</el-radio>
                        <el-radio v-model="modelSource" label="3">我的模型</el-radio>
                    </div>
                </div>
            </div>
            <div class="demand-info-three">
                <div class="demand-info-left">
                    <div class="demand-info-title">模型名称</div>
                    <div class="demand-info-content">
                        <el-select v-model="value" placeholder="请选择分类">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="demand-info-right">
                    <div class="demand-info-title">模型标签</div>
                    <div class="demand-info-content">
                        <el-input placeholder="请输入多个标签 使用'、'分割" v-model="label" clearable></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="model-title">
            <span>需求名称</span>
        </div>
        <div class="model">
            <el-upload
            class="upload-demo"
            action=""
            ref="upload"
            auto-upload:false
            accept=".png"
            :limit="1"
            :file-list="file"
            :show-file-list="true"
            :http-request="uploadFile"
            multiple>
                <div class="upload">
                    <img src="../../assets/dentistry/upload.png" alt="">
                </div>
                <div class="el-upload__text">请上传STL、OBJ、AMF、3MF、STEP格式文件</div>
                <div class="file-list" slot="file" v-if="file.length > 0">
                    <div class="list">
                        <div class="file-top">
                            <div class="file-top-left">
                                <img :src="file[0].img" alt="">
                            </div>
                            <div class="file-top-right">
                                <div class="file-top-right-top">{{file[0].name}}</div>
                                <div class="file-top-right-bottom">文件大小：{{file[0].size}}MB</div>
                            </div>
                        </div>
                        <div class="file-bot"></div>
                        <img class="wrong" src="../../assets/wrong.png" alt="" @click="removeFile">
                    </div>
                </div>
            </el-upload>
        </div>
        <div class="bottom-btn">
            <div class="save" @click="nextFun(1)">保存模型</div>
            <div class="submit" @click="denAdd">下一步</div>
        </div>
    </div>
</template>

<script>
import { getType , getDataDet , addDemand } from "../../api/home"
import { cosUpload } from '../../utils/cosUtils';
import { addModel , fixModel } from "../../api/layout/model"
    export default {
        data() {
            return {
                demandName:"",  //需求名称
                modelName:"",  //模型名称
                modelSource: '1',  //模型来源
                label:"",
                img:{
                    img:"",
                    size:"",
                    name:""
                },
                options: [],
                value: '',
                file: []
            }
                
        },
        methods: {
            nextFun(val){
                if(val == 2){
                    this.denAdd()
                }
                if(this.$store.getters.info["status"] == "fix"){
                    this.fixModel(val)
                }else{
                    this.submit(val)
                }
            },
            // 上传需求
            submitDem(val){
                let params={
                    type:1,
                    name:this.model.name,
                    category_id:this.model.type,
                    model_id:this.$store.getters.info["demand-id"],
                    printer:"",
                    material:"",
                    test:"3,4",
                    express_type:"",
                    express_address:"",
                    express_company:"",
                    duration:"",
                    number:"",
                    is_fix:this.$store.getters.info["is_fix"]
                }
                // console.log(params);
                addDemand(params).then(res=>{
                    if(res.code == 1 && val == 2){
                        this.payDemand(res.data.id)
                    }
                    this.$store.commit('closeDemFlag')
                }).catch(err=>{
                    console.log(err);
                })
            },
            // 下一步
            denAdd(){
                this.$store.commit("setDem",{title:'demand-img',val:this.file[0].img})
                this.$store.commit("setDem",{title:'demand-name',val:this.file[0].name})
                this.$store.commit("setDem",{title:'demand-size',val:this.file[0].size})
                this.$store.commit("setDem",{title:'demand-label',val:this.label})
                this.$store.commit("setDem",{title:'demandName',val:this.demandName})
                this.$store.commit("setDem",{title:'demand-type',val:this.value})
                this.$store.commit("setDem",{title:'model-name',val:this.modelName})
                this.$store.commit("setDem",{title:'model-source',val:this.modelSource})

                this.$emit('addAct')
            },
            // 上传图片
            removeFile(){
                this.$refs.upload.clearFiles()
                this.file = []
            },
            // 上传模型
            submit(val){
                let params={
                    name:this.modelName,
                    category_id:this.value,
                    source:this.modelSource,
                    label:this.label,
                    file: this.file[0].img,
                    image: this.file[0].img,
                    size: "24*8*2mm",
                    file_size: "122m",
                    volume:"384mm",
                    area: "500mm"
                }
                addModel(params).then(res=>{
                    this.$message({
                        message: `${res.msg}`,
                        type: 'success'
                    });
                    console.log(res.data.id,"res.data.id");
                    this.$store.commit("setDem",{title:'demand-id',val:res.data.id})
                    // this.denAdd()
                    // if(res.code == 1 && val == 1){
                    //     this.$store.commit('closeDemFlag')
                    // }else{
                    //     this.$store.commit("setDem",{title:'demand-id',val:res.data.id})
                    //     // console.log();
                    //     this.denAdd()
                    // }
                }).catch(err=>{
                    console.log(err);
                })
            },
            // 修改模型
            fixModel(val){
                let params={
                    id:this.$store.getters.info["demand-id"],
                    name:this.modelName,
                    category_id:this.value,
                    source:this.modelSource,
                    label:this.label,
                    file: this.file[0].img,
                    image: this.file[0].img,
                    size: "24*8*2mm",
                    file_size: "122m",
                    volume:"384mm",
                    area: "500mm"
                }
                fixModel(params).then(res=>{
                    console.log(res.data.id,"res.data.id");
                    if(res.code == 1 && val == 1){
                        this.$store.commit('closeDemFlag')
                    }else{
                        this.$store.commit("setDem",{title:'demand-id',val:res.data.id})
                        // console.log();
                        this.denAdd()
                    }
                }).catch(err=>{
                    console.log(err);
                })
            },
            // 上传文件
            async uploadFile(item) {
                const fileUrl = await cosUpload(item.file);
                // console.log(item,"文件上传");
                // this.img.img = fileUrl;
                // this.img.name = item.file.name;
                let size = item.file.size / 1024 / 1024
                this.img.size = size.toFixed(2)
                this.file.push({
                    img:fileUrl,
                    name:item.file.name,
                    size:size.toFixed(2),
                })
            },
            // 获取分类
            async getTypeInfo(){
                var model = []
                // var demand = []
                await getType({
                    id:0
                }).then(res=>{
                    model = res.data.category.find(obj => obj.id === 12).node;
                    for(var i=0;i<model.length; i++){
                        this.options.push(
                            {
                                value:model[i].id,
                                label:model[i].name
                            }
                        )
                    }
                }).catch(err=>{
                    console.log(err)
                })
                // console.log(this.options,"options");
            },
            // 获取信息
            getInfo(){
                getDataDet({id:this.$store.getters.shopId}).then(res=>{
                    console.log(res);
                    this.$store.commit("setMat",res.data.material)
                    this.$store.commit("setPro",res.data.product)
                }).catch(err=>{
                    console.log(err,"err");
                })
            }
        }, 
        mounted(){
            this.getTypeInfo()

            const modelInfo = this.$store.getters.info

            var model = {
                img: modelInfo["demand-img"],
                name: modelInfo["demandName"],
                dname: modelInfo["demand-name"],
                size: modelInfo["demand-size"],
                type: modelInfo["demand-type"],
                label: modelInfo["demand-label"],
                type: modelInfo["demand-type"],
                volume: "384mm²",
                area: "500mm³",
            }

            this.demandName = model.name ? model.name : ""
            this.modelName = model.dname ? model.dname : ""
            this.label = model.label ? model.label : ""
            this.value = model.type ? model.type : ""
            if(this.$store.getters.info["status"] == "fix"){
                this.file.push({
                    name:this.modelName,
                    img: model.img,
                    size: model.size,
                })
            }
            console.log(model,this.file,"model");
        }
    }
</script>

<style lang="scss" scoped>
.demandOne{
    .demand-info{
        .demand-info-title{
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
        }
        .demand-info-one{
            margin: .63rem 0rem .38rem;
            width: 6.63rem;
            height: .81rem;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }
        .demand-info-two{
            height: .81rem;
            display: flex;
            justify-content: space-between;
            .demand-info-left{
                width: 6.63rem;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
            .demand-info-right{
                width: 6.63rem;   
                display: flex;
                justify-content: space-around;
                flex-direction: column;
            }
        }
        .demand-info-three{
            height: .81rem;
            display: flex;
            justify-content: space-between;
            margin: .38rem 0rem;
            .demand-info-left{
                width: 6.63rem;
                display: flex;
                justify-content: space-between;
                flex-direction: column;   
                .demand-info-content{
                    ::v-deep{
                        .el-input__inner{
                            width: 6.63rem;
                        }
                    }
                }
            }
            .demand-info-right{
                width: 6.63rem;   
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
        }
    }
    .model-title{
        margin: .63rem 0rem .13rem;
        :nth-child(1){
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-right: .1rem;
        }
    }
    .model{
        padding: .38rem;
        box-sizing: border-box;
        width: 14.5rem;
        height: 6.1rem;
        border-radius: 0rem 0rem 0rem 0rem;
        border: .01rem solid #D7DBE6;
        .upload{
            width: 2.13rem;
            height: 2.13rem;
            background: url("../../assets/dentistry/upload-bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: .6rem;
                height: .6rem;
            }
        }
        .file-list{
            position: relative;
            padding: .19rem .29rem 0rem .23rem;
            box-sizing: border-box;
            width: 100%;
            height: .93rem;
            background: #F8FAFB;
            display: flex;
            flex-direction: column;
            .list{
                .file-top{
                    display: flex;
                    .file-top-left{
                        img{
                            width: .4rem;
                            height: .4rem;
                        }
                        margin-right: .13rem;
                    }
                    .file-top-right{
                        display: flex;
                        flex-direction: column;
                        .file-top-right-top{
                            font-weight: 500;
                            font-size: .15rem;
                            color: #788086;
                        }
                        .file-top-right-bottom{
                            font-weight: 300;
                            font-size: .13rem;
                            color: #788086;
                        }
                    }
                }
                .wrong{
                    cursor: pointer;
                    width: .2rem;
                    height: .2rem;
                    position: absolute;
                    right: .13rem;
                }
            }
        }
        ::v-deep{
            .el-upload{
                width: 13.75rem;
                height: 4.05rem;
                border: .01rem dashed #D7DBE6;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .el-upload__text{
                font-weight: 400;
                font-size: .15rem;
                color: #8594A2;
            }
        }
    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        font-weight: 500;
        font-size: .17rem;
        .save{
            margin-right: .25rem;
            width: 1.2rem;
            height: .5rem;
            text-align: center;
            line-height: .5rem;
            background: #FFFFFF;
            border-radius: 0rem 0rem 0rem 0rem;
            border: .01rem solid #09A966;
            color: #09A966;
        }
        .submit{
            width: 1.02rem;
            height: .5rem;
            text-align: center;
            color: #788086;
            line-height: .5rem;
            background: #EFEFEF;
        }
    }
}
</style>