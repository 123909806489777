const dentistry = {
    state: {
        denAct:1,
    },

    mutations: {
        denAdd:(state)=>{
            state.denAct = state.denAct += 1;
            console.log(state.denAct,"denAct")
        },
        denMines:(state)=>{
            state.denAct = state.denAct -= 1;
        },
        CloseDen:(state)=>{
            state.denAct = 1;
        }
    }
}

export default dentistry
