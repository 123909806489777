<template>
    <div class="sucess">
        <div class="suc-title">恭喜您！通过对知识产权库比对查重，您的作品没有侵权行为，可进行产权登记申请。</div>
        <div class="suc-box">
            <div class="demand-info-one">
                <div class="demand-info-left">
                    <div class="demand-info-title">作品名称</div>
                    <div class="demand-info-content">
                        <el-input placeholder="请输入内容" v-model="demandName" clearable></el-input>
                    </div>
                </div>
                <div class="demand-info-right">
                    <div class="demand-info-title">作品类型</div>
                    <div class="demand-info-content">
                        <el-select v-model="value" placeholder="请选择分类">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="demand-info-two">
                <div class="demand-info-left">
                    <div class="demand-info-title">作者姓名</div>
                    <div class="demand-info-content">
                        <el-input placeholder="请输入模型名称" v-model="modelName" clearable></el-input>
                    </div>
                </div>
                <div class="demand-info-right">
                    <div class="demand-info-title">身份证号</div>
                    <div class="demand-info-content">
                        <el-input placeholder="请输入模型名称" v-model="code" clearable></el-input>
                    </div>
                </div>
            </div>
            <div class="demand-info-three">
                <div class="demand-info-left">
                    <div class="demand-info-title">联系电话</div>
                    <div class="demand-info-content">
                        <el-input placeholder="请输入模型名称" v-model="phone" clearable></el-input>
                    </div>
                </div>
                <div class="demand-info-right">
                    <div class="demand-info-title">联系地址</div>
                    <div class="demand-info-content">
                        <el-input placeholder="请输入多个标签 使用'、'分割" v-model="address" clearable></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-btn">
            <div class="save" @click="cancel">取消</div>
            <div class="submit" @click="submit">保存</div>
        </div>
    </div>
</template>

<script>
import { getType } from "../../api/home"
import { addequ } from "../../api/layout/model"
    export default {
        data(){
            return{
                demandName:"",
                value:"",
                modelName:"",
                code:"",
                phone:"",
                address:"",
                options:[]
            }
        },
        methods:{
            cancel(){
                this.$store.commit("setequity",false)
            },
            submit(){
                // 保存
                // 判断是否为空
                if(this.demandName == "" || this.value == "" || this.modelName == "" || this.code == "" || this.phone == "" || this.address == ""){
                    this.$message.error("请填写完整信息");
                    return;
                }
                // 判断是否为空
                let params={
                    model_id:1,
                    name:this.demandName,
                    category_id:this.value,
                    author:this.modelName,
                    id_card:this.code,
                    phone:this.phone,
                    address:this.address
                }
                console.log(params);
                this.$store.commit("setEquity",params)
                this.$store.commit("setequity",false)
            },
            // 获取分类
            async getTypeInfo(){
                var model = []
                // var demand = []
                await getType({
                    id:0
                }).then(res=>{
                    model = res.data.category.find(obj => obj.id === 12).node;
                    for(var i=0;i<model.length; i++){
                        this.options.push(
                            {
                                value:model[i].id,
                                label:model[i].name
                            }
                        )
                    }
                }).catch(err=>{
                    console.log(err)
                })
                // console.log(this.options,"options");
            },
        },
        mounted(){
            this.getTypeInfo()
        }
    }
</script>

<style lang="scss" scoped>
.sucess{
    width: 100%;
    .suc-title{
        display: flex;
        justify-content: center;
        font-weight: 500;
        font-size: .2rem;
        color: #09A966;
        margin: .63rem 0rem .63rem;
    }
    .suc-box{
        .demand-info-title{
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
        }
        .demand-info-one{
            margin: .63rem 0rem .38rem;
            height: .81rem;
            display: flex;
            justify-content: space-between;
            .demand-info-left{
                width: 6.63rem;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
            .demand-info-right{
                width: 6.63rem;   
                display: flex;
                justify-content: space-around;
                flex-direction: column;
                width: 6.63rem;
                display: flex;
                justify-content: space-between;
                flex-direction: column;   
                .demand-info-content{
                    ::v-deep{
                        .el-input__inner{
                            width: 6.63rem;
                        }
                    }
                }
            }
        }
        .demand-info-two{
            height: .81rem;
            display: flex;
            justify-content: space-between;
            .demand-info-left{
                width: 6.63rem;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
            .demand-info-right{
                width: 6.63rem;   
                display: flex;
                justify-content: space-around;
                flex-direction: column;
            }
        }
        .demand-info-three{
            height: .81rem;
            display: flex;
            justify-content: space-between;
            margin: .38rem 0rem;
            .demand-info-left{
                width: 6.63rem;
                display: flex;
                justify-content: space-between;
                flex-direction: column;   
                .demand-info-content{
                    ::v-deep{
                        .el-input__inner{
                            width: 6.63rem;
                        }
                    }
                }
            }
            .demand-info-right{
                width: 6.63rem;   
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
        }
    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        font-weight: 500;
        font-size: .17rem;
        .save{
            margin-right: .25rem;
            width: 1.2rem;
            height: .5rem;
            text-align: center;
            line-height: .5rem;
            background: #FFFFFF;
            border-radius: 0rem 0rem 0rem 0rem;
            border: .01rem solid #09A966;
            color: #09A966;
        }
        .submit{
            width: 1.02rem;
            height: .5rem;
            text-align: center;
            color: #FFFFFF;
            line-height: .5rem;
            background: #09A966;
        }
    }
}
</style>