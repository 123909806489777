import Vue from 'vue'
import Vuex from 'vuex'
import alert from "./modules/alert"
import router from "./modules/router"
import userInfo from './modules/user'
import getters from './getters'
import dentistry from "./modules/dentistry"
import demand from "./modules/demand"
import model from "./modules/model"
import automobile from "./modules/automobile"
import Type from "./modules/type"

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        alert,
        dentistry,
        demand,
        model,
        automobile,
        userInfo,
        Type,
        router
    },
    getters
})

export default store
