<template>
    <div class="demandOne">
        <div class="demand-info">
            <div class="demand-info-two">
                <div class="demand-info-left">
                    <div class="demand-info-title">模型名称</div>
                    <div class="demand-info-content">
                        <el-input placeholder="请输入模型名称" v-model="modelName" clearable></el-input>
                    </div>
                </div>
                <div class="demand-info-right">
                    <div class="demand-info-title">模型来源</div>
                    <div class="demand-info-content">
                        <el-radio v-model="modelSource" label="1">上传模型</el-radio>
                        <el-radio v-model="modelSource" label="2">在线制图</el-radio>
                    </div>
                </div>
            </div>
            <div class="demand-info-three">
                <div class="demand-info-left">
                    <div class="demand-info-title">模型分类</div>
                    <div class="demand-info-content">
                        <el-select v-model="value" placeholder="请选择分类">
                            <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="demand-info-right">
                    <div class="demand-info-title">模型标签</div>
                    <div class="demand-info-content">
                        <el-input placeholder="请输入多个标签 使用'、'分割" v-model="label" clearable></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="model-title">
            <span>上传模型</span>
        </div>
        <div class="model" v-if="state == 1">
            <el-upload
            class="upload-demo"
            action=""
            ref="upload"
            auto-upload:false
            accept=".png"
            :limit="1"
            :file-list="file"
            :show-file-list="true"
            :http-request="uploadFile"
            multiple>
                <div class="upload">
                    <img src="../../assets/dentistry/upload.png" alt="">
                </div>
                <div class="el-upload__text">请上传STL、OBJ、AMF、3MF、STEP格式文件</div>
                <div class="file-list" slot="file">
                    <div class="list">
                        <div class="file-top">
                            <div class="file-top-left">
                                <img :src="img.img" alt="">
                            </div>
                            <div class="file-top-right">
                                <div class="file-top-right-top">{{img.name}}</div>
                                <div class="file-top-right-bottom">文件大小：{{img.size}}MB</div>
                            </div>
                        </div>
                        <div class="file-bot"></div>
                        <img class="wrong" src="../../assets/wrong.png" alt="" @click="removeFile">
                    </div>
                </div>
            </el-upload>
        </div>
        <div class="model_two" v-else>
            <div class="model_info">
                <div class="left">
                    <div class="model-left">
                        <img :src="img.img" alt="">
                    </div>
                    <div class="model-right">
                        <div class="model-right-top">{{img.name}}</div>
                        <div class="model-right-bot">
                            <span class="title">长宽高：</span><span class="val">24*8*2mm</span>
                            <span class="title">体积：</span><span class="val">384mm</span>
                            <span class="title">表面积：</span><span class="val">500mm</span>
                        </div>
                        <div class="model-right-bot">
                            <span class="title">文件大小：</span><span class="val">{{img.size}}MB</span>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <div :class="stateType == 1 ? 'right_one' : 'right_one mo'" @click="equity">产品登记</div>
                    <div class="border">|</div>
                    <div :class="stateType == 1 ? 'right_two' : 'right_two mo'">编辑模型</div>
                    <div class="border">|</div>
                    <div :class="stateType == 1 ? 'right_three' : 'right_three mo'" @click="delModel">删除模型</div>
                </div>
            </div>
            <div class="model_tran">
                <div class="model_title">
                    <div class="title_left">
                        <span>上传完成</span>
                        <img src="../../assets/model/reject.png" alt="">
                        <span>校验文件是否满足打印条件</span>
                    </div>
                    <div class="title_right">
                        <div class="title_btn" @click="startCheck">文件校验</div>
                    </div>
                </div>
                <div class="model_type" v-if="uploadComplete">
                    <div class="type_title">
                        <span>校验文件：</span>
                        <span v-if="compleDate < 100">检验分析中{{compleDate}}%</span>
                        <span v-else>校验完成，文件满足打印要求!</span>
                    </div>
                    <div class="check-status">  
                        <div class="item">  
                            <div class="title">文件格式</div> 
                            <div class="border"></div> 
                            <div class="status" :class="{'success': formatStatus === '满足要求', 'processing': formatStatus === '校验中'}">{{ formatStatus }}</div>  
                        </div>  
                        <div class="item">  
                            <div class="title">文件大小</div>  
                            <div class="border"></div> 
                            <div class="status" :class="{'success': sizeStatus === '满足要求', 'processing': sizeStatus === '校验中'}">{{ sizeStatus }}</div>  
                        </div>  
                        <div class="item">  
                            <div class="title">文件结构</div>  
                            <div class="border"></div> 
                            <div class="status" :class="{'success': structureStatus === '满足要求', 'processing': structureStatus === '校验中'}">{{ structureStatus }}</div>  
                        </div>  
                        <div class="item">  
                            <div class="title">校验文件内容</div>  
                            <div class="border"></div> 
                            <div class="status" :class="{'success': contentStatus === '满足要求', 'processing': contentStatus === '校验中'}">{{ contentStatus }}</div>  
                        </div>  
                        <div class="item">  
                            <div class="title">校验文件完整性</div>  
                            <div class="border"></div> 
                            <div class="status" :class="{'success': integrityStatus === '满足要求', 'processing': integrityStatus === '校验中'}">{{ integrityStatus }}</div>  
                        </div>
                    </div> 
                    <div class="type_title-mubiao" v-show="compleDate == 100">
                        <span>模拟测试：</span>
                        <span class="btn" @click="setDraw">编辑测试目标</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-btn">
            <div class="save" @click="resize">取消</div>
            <div :class="compleDate == 100 ? 'submit end' : 'submit'" @click="submit(1)">保存</div>
        </div>
    </div>
</template>

<script>
import { getType } from "../../api/home"
import { addModel , addequ } from "../../api/layout/model"
import { cosUpload } from '../../utils/cosUtils';
    export default {
        data() {
            return {
                modelName:"",  //模型名称
                modelSource: '1',  //模型来源
                options: [],
                value: '',
                file: [],
                img:{
                    img:"",
                    size:"",
                    name:""
                },
                label:"",
                state:1,
                stateType:1,
                uploadComplete: false, // 假设文件已经上传完成  
                checkInProgress: false, // 校验是否正在进行中  
                formatStatus: '等待校验',  
                sizeStatus: '等待校验',  
                structureStatus: '等待校验',  
                contentStatus: '等待校验',  
                integrityStatus: '等待校验',
                compleDate:0,
                intervalId:null,
                success:false,
                verifyStatus: "start",
            }
                
        },
        methods: {
            // 删除模型
            delModel(){
                this.state = 1
                this.img = {}
                this.compleDate = 0
                this.uploadComplete = false
                this.checkInProgress = false
            },
            setDraw(){
                this.$store.commit("setDrawFlag",!this.$store.getters.drawFlag)
            },
            // 产权登记
            equity(){
                this.$store.commit("setequity",true)
            },
            submit(){
                if(this.modelName == "" || this.value == "" || this.label == ""){
                    this.$message.error("请填写完整信息");
                    return;
                }
                if(this.compleDate != 100){
                    this.$message.error("请先进行模型校验");
                    return;
                }
                let params={
                    name:this.modelName,
                    category_id:this.value,
                    source:this.modelSource,
                    label:this.label,
                    file: this.img.img,
                    image: this.img.img,
                    size: "24*8*2mm",
                    file_size: "122m",
                    volume:"384mm",
                    area: "500mm"
                }
                var equity = this.$store.getters.equity
                console.log(equity,params);
                addModel(params).then(res=>{
                    equity.model_id = res.data.id
                    if(JSON.stringify(equity) != "{}" && res.code == 1){
                        addequ(equity).then(res=>{
                            console.log(res,"res");
                            this.$message({
                            message: '添加成功',
                            type: 'success'
                            });
                        }).catch(err=>{
                            console.log(err);
                        })
                    }
                    this.$message({
                    message: '添加成功',
                    type: 'success'
                    });
                    console.log(res.data,"res.data");
                }).catch(err=>{
                    console.log(err);
                })
            },
            // 上传文件
            async uploadFile(item) {
                const fileUrl = await cosUpload(item.file);
                // console.log(item,"文件上传");
                this.img.img = fileUrl;
                this.img.name = item.file.name;
                let size = item.file.size / 1024 / 1024
                this.img.size = size.toFixed(2)
                setTimeout(()=>{
                    this.state = 2
                },700)
            },
            removeFile(){
                this.file = []
            },
            async getTypeInfo(){
                var model = []
                // var demand = []
                await getType({
                    id:0
                }).then(res=>{
                    model = res.data.category.find(obj => obj.id === 12).node;
                    for(var i=0;i<model.length; i++){
                        this.options.push(
                            {
                                value:model[i].id,
                                label:model[i].name
                            }
                        )
                    }
                }).catch(err=>{
                    console.log(err)
                })
                // console.log(this.options,"options");
            },
            resize(){
                this.$store.commit('closeModelFlag')
            },
            startCheck() {  
                if (this.checkInProgress) return; // 如果已经在校验中，则不执行  
            
                this.checkInProgress = true;  
                this.uploadComplete = true;

                this.intervalId = setInterval(() => {  
                    if (this.compleDate >= 100) {  
                    clearInterval(this.intervalId); // 清除定时器  
                    this.intervalId = null;  
                    } else {  
                        this.compleDate++; // 每次增加1  
                    }  
                }, 25); // 每10毫秒更新一次进度（可以根据需要调整）
                
                this.simulateCheckProcess(); // 模拟校验过程
            },
            simulateCheckProcess() {
                // 这里只是模拟，实际情况中会根据实际的校验逻辑来更新状态
                setTimeout(() => {  
                    this.formatStatus = '满足要求';  
                    this.sizeStatus = '满足要求';  
                    this.structureStatus = '校验中';  
                }, 500);  
            
                setTimeout(() => {  
                    this.structureStatus = '满足要求';  
                    // 其他校验点逻辑...  
                    this.contentStatus = '校验中';  
                    this.integrityStatus = '校验中';
                }, 1000);  
            
                // 假设所有校验都完成  
                setTimeout(() => {  
                    this.contentStatus = '等待校验';
                    this.integrityStatus = '等待校验';
                }, 1500);  

                setTimeout(() => {  
                    this.contentStatus = '满足要求';
                    this.integrityStatus = '满足要求';
                }, 2000);  
            },  
        }, 
        mounted(){
            this.getTypeInfo()
            // this.$store.commit("getTypeInfo")
        }
    }
</script>

<style lang="scss" scoped>
.demandOne{
    .demand-info{
        .demand-info-title{
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
        }
        .demand-info-one{
            margin: .63rem 0rem .38rem;
            width: 6.63rem;
            height: .81rem;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
        }
        .demand-info-two{
            height: .81rem;
            display: flex;
            justify-content: space-between;
            .demand-info-left{
                width: 6.63rem;
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
            .demand-info-right{
                width: 6.63rem;   
                display: flex;
                justify-content: space-around;
                flex-direction: column;
            }
        }
        .demand-info-three{
            height: .81rem;
            display: flex;
            justify-content: space-between;
            margin: .38rem 0rem;
            .demand-info-left{
                width: 6.63rem;
                display: flex;
                justify-content: space-between;
                flex-direction: column;   
                .demand-info-content{
                    ::v-deep{
                        .el-input__inner{
                            width: 6.63rem;
                        }
                    }
                }
            }
            .demand-info-right{
                width: 6.63rem;   
                display: flex;
                justify-content: space-between;
                flex-direction: column;
            }
        }
    }
    .model-title{
        margin: .63rem 0rem .13rem;
        :nth-child(1){
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-right: .1rem;
        }
    }
    .model{
        padding: .38rem;
        box-sizing: border-box;
        width: 14.5rem;
        height: 6.1rem;
        border-radius: 0rem 0rem 0rem 0rem;
        border: .01rem solid #D7DBE6;
        .upload{
            width: 2.13rem;
            height: 2.13rem;
            background: url("../../assets/dentistry/upload-bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width: .6rem;
                height: .6rem;
            }
        }
        .file-list{
            position: relative;
            padding: .19rem .29rem 0rem .23rem;
            box-sizing: border-box;
            width: 100%;
            height: .93rem;
            background: #F8FAFB;
            display: flex;
            flex-direction: column;
            .list{
                .file-top{
                    display: flex;
                    .file-top-left{
                        img{
                            width: .4rem;
                            height: .4rem;
                        }
                        margin-right: .13rem;
                    }
                    .file-top-right{
                        display: flex;
                        flex-direction: column;
                        .file-top-right-top{
                            font-weight: 500;
                            font-size: .15rem;
                            color: #788086;
                        }
                        .file-top-right-bottom{
                            font-weight: 300;
                            font-size: .13rem;
                            color: #788086;
                        }
                    }
                }
                .wrong{
                    cursor: pointer;
                    width: .2rem;
                    height: .2rem;
                    position: absolute;
                    right: .13rem;
                    top: .13rem;
                }
            }
        }
        ::v-deep{
            .el-upload{
                width: 13.75rem;
                height: 4.05rem;
                border: .01rem dashed #D7DBE6;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
            .el-upload__text{
                font-weight: 400;
                font-size: .15rem;
                color: #8594A2;
            }
        }
    }
    .model_two{
        padding: .38rem;
        box-sizing: border-box;
        width: 14.5rem;
        // height: 6.1rem;
        display: flex;
        flex-direction: column;
        border-radius: 0rem 0rem 0rem 0rem;
        border: .01rem solid #D7DBE6;
        .model_info{
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 1.4rem;
            // background: #F8FAFB;
            // border: .01rem solid #F8FAFB;
            .left{
                width: 7.25rem;
                display: flex;
                .model-left{
                    img{
                        margin: .13rem .23rem;
                        width: 1.15rem;
                        height: 1.15rem;
                    }
                }
                .model-right{
                    .model-right-top{
                        font-weight: 500;
                        font-size: .17rem;
                        color: #1D1F23;
                        margin: .4rem 0rem .17rem;
                    }
                    .model-right-bot{
                        font-weight: 300;
                        font-size: .13rem;
                        color: #788086;
                        .val{
                            margin-right: .13rem;
                        }
                    }
                }
            }
            .right{
                padding-right: .38rem;
                display: flex;
                // flex-direction: column;
                .right_one,.right_two,.right_three{
                    font-weight: 500;
                    font-size: .15rem;
                    color: #09A966;
                }
                :last-child{
                    color: #E75763;
                }
                .border{
                    color: #8594A2;
                    margin: 0rem .19rem;
                }
                .mo{

                }
            }
        }
        .model_tran{
            display: flex;
            flex-direction: column;
            margin-top: .25rem;
            // width: 1120px;
            // height: 60px;
            background: #F8FAFB;
            .model_title{
                padding: 0rem .38rem 0rem .25rem;
                box-sizing: border-box;
                height: .75rem;
                display: flex;
                justify-content: space-between;
                .title_left{
                    display: flex;
                    align-items: center;
                    :nth-child(1){
                        font-weight: 500;
                        font-size: .15rem;
                        color: #1D1F23;
                    }
                    img{
                        width: .17rem;
                        height: .17rem;
                        margin: 0rem .05rem 0rem .1rem;
                    }
                    :last-child{
                        font-weight: 400;
                        font-size: .15rem;
                        color: #E75763;
                    }
                }
                .title_right{
                    margin: auto 0rem;
                    .title_btn{
                        cursor: pointer;
                        width: .8rem;
                        height: .36rem;
                        line-height: .36rem;
                        text-align: center;
                        background: #09A966;
                        font-weight: 500;
                        font-size: .15rem;
                        color: #FFFFFF;
                    }
                }
            }
            .model_type{
                display: flex;
                flex-direction: column;
                width: 100%;
                border-top: .01rem solid #D7DBE6;
                padding: 0rem .23rem;
                box-sizing: border-box;
                .type_title{
                    width: 100%;
                    height: .39rem;
                    align-items: center;
                    // border-bottom: 1px solid black;
                    display: flex;
                    justify-content: space-between;
                    padding: 0rem .35rem;
                    box-sizing: border-box;
                }
                .check-status{
                    // margin-top: 20px;  
                    .item{
                        height: .39rem;
                        // border-bottom: 1px solid black;
                        padding: 0rem .35rem;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        .title{
                            width: 1.13rem;
                        }
                        .border{
                            width: 3.75rem;
                            margin: 0rem .25rem;
                        }
                        .status{

                        }
                    }
                    .status {  
                        margin-left: .13rem;  
                        display: inline-block;  
                        padding: .05rem .1rem;  
                        border-radius: .05rem;  
                    }  
                    .status.success {  
                        color: #09A966;
                    }  
                    .status.processing {  
                        color: #F5B40C;
                    }  
                }
                .type_title-mubiao{
                    margin-top: .25rem;
                    width: 100%;
                    height: .39rem;
                    align-items: center;
                    // border-bottom: 1px solid black;
                    display: flex;
                    justify-content: space-between;
                    padding: 0rem .35rem;
                    box-sizing: border-box;
                    .btn{
                        display: block;
                        width: 88px;
                        height: 29px;
                        line-height: 29px;
                        text-align: center;
                        background: #09A966;
                        border-radius: 0px 0px 0px 0px;
                        border: 1px solid #09A966;
                        font-weight: 500;
                        font-size: 12px;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        font-weight: 500;
        font-size: .17rem;
        .save{
            margin-right: .25rem;
            width: 1.2rem;
            height: .5rem;
            text-align: center;
            line-height: .5rem;
            background: #FFFFFF;
            border-radius: 0rem 0rem 0rem 0rem;
            border: .01rem solid #09A966;
            color: #09A966;
        }
        .submit{
            width: 1.02rem;
            height: .5rem;
            text-align: center;
            color: #788086;
            line-height: .5rem;
            background: #EFEFEF;
        }
        .end{
            background: #09A966;
            color: #FFFFFF;
        }
    }
}
</style>