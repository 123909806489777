<template>
    <div class="demandFive">
        <div class="model-title">
            <span>需求名称</span>
        </div>
        <div class="model">
            <div class="left">
                <div class="model-left">
                    <img :src="model.img" alt="">
                </div>
                <div class="model-right">
                    <div class="model-right-top">{{model.name}}</div>
                    <div class="model-right-bot">
                        <span class="title">长宽高：</span><span class="val">{{model.size}}</span>
                        <span class="title">体积：</span><span class="val">{{model.volume}}</span>
                        <span class="title">表面积：</span><span class="val">{{model.area}}</span>
                    </div>
                </div>
            </div>
            <div class="border"></div>
            <div class="right">
                <div class="right-top">
                    <span v-for="(item,index) in model.label" :key="index">{{item}}</span>
                </div>
                <div class="right-bot">
                    <span class="title">模具分类：</span><span class="val">产品设计</span>
                </div>
            </div>
        </div>
        <div class="print-title">
            <span>打印机需求</span>
        </div>
        <div class="print">
            <div class="left">
                <div class="print-left">
                    <img :src="print.image" alt="">
                </div>
                <div class="print-right">
                    <div class="print-right-top">{{print.name}}</div>
                    <div class="print-right-bot">
                        <span class="title">打印尺寸：</span><span class="val">{{print.size}}</span>
                        <span class="title">生产商：</span><span class="val">{{print.producer}}</span>
                        <span class="title">使用耗材：</span><span class="val">{{print.description}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="demand-title">
            <span>耗材需求</span>
        </div>
        <div class="demand">
            <div class="left">
                <div class="demand-left">
                    <img :src="materAct.image" alt="">
                </div>
                <div class="demand-right">
                    <div class="demand-right-top"><span>{{materAct.name}}</span>生产商：<span>{{materAct.producer}}</span> </div>
                    <div class="demand-right-bot">{{materAct.description}}</div>
                </div>
            </div>
        </div>
        <div class="express">
            <div class="title">工期需求</div>
            <div>根据您的需求信息预估工期在<span>3~5个工作日</span>区间，在此范围内，您可对交期进行精确调整，从而<span>减免</span>或<span>额外支付</span>一定费用。</div>
        </div>
        <div class="express-content">
            <div :class="index == expressAct ? 'active item' : 'item'" v-for="(item,index) in 7" :key="index" @click="expressAct = index">{{index + 1}}个工作日</div>
        </div>
        <div class="logistics">
            <div class="title">物流需求</div>
            <div>结合交期需求与物流数据，如果您在今天<span>23:00前</span>下单，预计<span>11月25日之前</span>可到货</div>
        </div>
        <div class="logistics-content">
            <div class="log-top">
                <img src="../../assets/detail/mater-one.png" alt="">
                <span>{{model.kuai}}快递</span>
                <span>|</span>
                <span>SF773275985449</span>
            </div>
            <div class="log-bot">
                <el-timeline>
                    <el-timeline-item
                    v-for="(activity, index) in activities"
                    :key="index"
                    :icon="activity.icon"
                    :type="activity.type"
                    :color="activity.color"
                    :size="activity.size"
                    :timestamp="activity.timestamp">
                        {{activity.content}}
                    </el-timeline-item>
                </el-timeline>
            </div>
        </div>
        <div class="price-title">
            <span>价格明细</span>
        </div>
        <div class="price-content">
            <div class="price-title">
                <div class="one">
                    <span>服务项目</span>
                </div>
                <div class="two">
                    <span>数量</span>
                </div>
                <div class="three">
                    <span>服务商</span>
                </div>
                <div class="four">
                    <span>金额</span>
                </div>
            </div>
            <div class="price-item">
                <div class="one">
                    <span>产品打印服务</span>
                </div>
                <div class="two">
                    <span>1</span>
                </div>
                <div class="three">
                    <span>东莞市科品快速成型技术有限公司</span>
                </div>
                <div class="four">
                    <span>￥500</span>
                </div>
            </div>
            <div class="price-item">
                <div class="one">
                    <span>产品打印耗材</span>
                </div>
                <div class="two">
                    <span :class="printNumb == 1 ? 'dis jian' : 'jian'" @click="printNumb > 1 ? printNumb-- : 1">-</span>
                    <span class="price">{{printNumb}}</span>
                    <span class="jia" @click="printNumb++">+</span>
                </div>
                <div class="three">
                    <span>东莞市科品快速成型技术有限公司</span>
                </div>
                <div class="four">
                    <span>￥{{maNumber}}</span>
                </div>
            </div>
            <div class="price-item">
                <div class="one">
                    <span>物流运输</span>
                </div>
                <div class="two">
                    <span>1</span>
                </div>
                <div class="three">
                    <span>{{model.kuai}}</span>
                </div>
                <div class="four">
                    <span>￥30</span>
                </div>
            </div>
            <div class="price-item">
                <div class="one">
                    <span>工期调整</span>
                </div>
                <div class="two"></div>
                <div class="three">
                    <span>东莞市科品快速成型技术有限公司</span>
                </div>
                <div class="four">
                    <span>￥0</span>
                </div>
            </div>
            <div class="price-title">
                <div class="one">
                    <span>优惠项目</span>
                </div>
            </div>
            <div class="price-item">
                <div class="one">
                    <span>满3件减30元优惠</span>
                </div>
                <div class="two">{{printNumb > 2 ? 1 : 0}}</div>
                <div class="three">
                    <span>平台补助</span>
                </div>
                <div class="four">
                    <span>-30</span>
                </div>
            </div>
            <div class="price-title">
                <div class="one">
                    <span>总计</span>
                </div>
                <div class="two">
                    <span></span>
                </div>
                <div class="three">
                    <span></span>
                </div>
                <div class="four ">
                    <span class="sumP">￥{{sum}}</span>
                </div>
            </div>
        </div>
        <div class="bottom-btn">
            <div class="save" @click="demMin">上一步</div>
            <div class="save" @click="demAdd(1)">保存方案</div>
            <div class="submit" @click="demAdd(2)">马上下单</div>
        </div>
    </div>
</template>

<script>
import { addDemand } from "../../api/home"
import { fixDemand , payDem } from "../../api/layout/index" 
    export default {
        data(){
            return{
                expressAct: 0,
                model:{
                    img:require("../../assets/detail/mater-one.png"),
                    name:"患者-牙齿模型.stl",
                    size:"24x8x2mm",
                    volume:"384mm²",
                    area:"500mm³",
                    label:["艺术品","毕业设计","3D打印"]
                },
                print:{
                    img:require("../../assets/detail/mater-one.png"),
                    name:"Ultimaker 2+",
                    size:"100x100x100mm",
                    volume:"Ultimaker",
                    area:"尼龙/PLA/ABS/PC/PP",
                },
                demand:{
                    img:require("../../assets/detail/mater-one.png"),
                    name:"PP",
                    producer:"Ultimaker",
                    text:"Ultimaker PP(聚丙烯)经久耐用。它具有高韧性、优异的抗疲劳性和低摩擦性。它还具有良好的耐化学腐蚀，耐高温和高电阻等特性。PP是世界上使用广泛的塑料之一，从电器元件到活铰链，它是制作原型和最终产品的重要材料。",
                },
                activities: [
                    {
                        content: '交付工厂地址',
                        timestamp: '广东省 东莞市 成华大道 二仙桥 ｜ 距离我450km',
                        color: '#0bbd87'
                    },
                    {
                        content: '收货地址',
                        timestamp: '河北省 邯郸市 丛台区 滏东北大街 工业设计中心',
                        color: '#0bbd87'
                    }
                ],
                materAct:{},
                printNumb:1,
            }
        },
        methods:{
            demMin(){
                this.$emit('minAct')
            },
            demAdd(val){
                if(this.$store.getters.info["status"] == "fix"){
                    this.fixDemand(val)
                }else{
                    this.submit(val)
                }
                this.$store.commit('closeDemFlag')
                this.$store.commit('closeDem')
            },
            pay(){
            },
            submit(val){
                let params={
                    type:1,
                    name:this.model.name,
                    category_id:this.model.type,
                    model_id:this.model.id,
                    printer:this.print.id,
                    material:this.materAct.id,
                    test:"3,4",
                    express_type:1,
                    express_address:this.model.address,
                    express_company:this.model.kuai,
                    duration:this.expressAct+1,
                    number:this.printNumb,
                    is_fix:this.model.is_fix
                }
                // console.log(params);
                addDemand(params).then(res=>{
                    if(res.code == 1 && val == 2){
                        this.payDemand(res.data.id)
                    }
                    this.$store.commit('closeDemFlag')
                }).catch(err=>{
                    console.log(err);
                })
            },
            fixDemand(val){
                let params={
                    id:this.$store.getters.info["id"],
                    type:1,
                    name:this.model.name,
                    category_id:this.model.type,
                    model_id:this.model.id,
                    printer:this.print.id,
                    material:this.materAct.id,
                    test:"3,4",
                    express_type:1,
                    express_address:this.model.address,
                    express_company:this.model.kuai,
                    duration:this.expressAct+1,
                    number:this.printNumb,
                    is_fix:this.model.is_fix
                }
                // console.log(params);
                fixDemand(params).then(res=>{
                    if(res.code == 1 && val == 2){
                        console.log("修改需求");
                        this.payDemand(res.data.id)
                    }
                    this.$store.commit('closeDemFlag')
                }).catch(err=>{
                    console.log(err);
                })
            },
            // 下单
            payDemand(val){
                payDem({id:val}).then(res=>{
                    console.log(res);
                }).catch(err=>{
                    console.log(err);
                })
            },
        },
        mounted(){
            console.log(this.$store.getters.info["number"]);
            if(this.$store.getters.info["status"] == "fix"){
                this.printNumb = this.$store.getters.info["number"]
            }

            const modelInfo = this.$store.getters.info

            this.activities[1].timestamp = modelInfo["express_addre"]

            this.model = {
                img: modelInfo["demand-img"],
                name:modelInfo["demandName"],
                size:modelInfo["demand-size"],
                type:modelInfo["demand-type"],
                label:modelInfo["demand-label"] ? modelInfo["demand-label"].split(",") : [],
                kuai:modelInfo["express_comp"],
                address:modelInfo["express_addre"],
                id:modelInfo["demand-id"],
                is_fix:modelInfo["is_fix"],
                volume:"384mm²",
                area:"500mm³",
            }

            this.print = this.$store.getters.materialItem
            // console.log(this.print,"print");

            this.materAct = this.$store.getters.productItem
            // console.log(this.materAct,"materAct");

            console.log(this.print,this.materAct,this.model);
        },
        computed:{
            maNumber(){
                console.log(this.print.price,"耗材单价");
                return this.print.price * this.printNumb
            },
            sum(){
                if(this.printNumb > 3){
                    return this.print.price * this.printNumb + 500
                }else{
                    return this.print.price * this.printNumb + 530
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.demandFive{
    .model-title{
        margin: .63rem 0rem .13rem;
        :nth-child(1){
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-right: .1rem;
        }
    }
    .model{
        display: flex;
        align-items: center;
        height: 1.4rem;
        background: #F8FAFB;
        border: .01rem solid #F8FAFB;
        .left{
            width: 7.25rem;
            display: flex;
            .model-left{
                img{
                    margin: .13rem .23rem;
                    width: 1.15rem;
                    height: 1.15rem;
                }
            }
            .model-right{
                .model-right-top{
                    font-weight: 500;
                    font-size: .17rem;
                    color: #1D1F23;
                    margin: .4rem 0rem .17rem;
                }
                .model-right-bot{
                    font-weight: 300;
                    font-size: .13rem;
                    color: #788086;
                    .val{
                        margin-right: .13rem;
                    }
                }
            }
        }
        .border{
            width: 0rem;
            height: .69rem;
            border: .01rem solid #D7DBE6;
        }
        .right{
            padding-left: .63rem;
            .right-top{
                display: flex;
                margin-bottom: .17rem;
                span{
                    display: block;
                    font-weight: 400;
                    font-size: .13rem;
                    color: #09A966;
                    padding: .03rem .09rem;
                    background: #EDFBF5;
                    margin-right: .06rem;
                }
            }
            .right-bot{
                .title{
                    font-weight: 300;
                    font-size: .13rem;
                    color: #8594A2;
                }
                .val{
                    font-weight: 500;
                    font-size: .13rem;
                    color: #1D1F23;
                }
            }
        }
    }
    .print-title{
        margin: .63rem 0rem .13rem;
        :nth-child(1){
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-right: .1rem;
        }
    }
    .print{
        display: flex;
        align-items: center;
        height: 1.4rem;
        background: #F8FAFB;
        border: .01rem solid #F8FAFB;
        .left{
            width: 7.25rem;
            display: flex;
            .print-left{
                img{
                    margin: .13rem .23rem;
                    width: 1.15rem;
                    height: 1.15rem;
                }
            }
            .print-right{
                .print-right-top{
                    font-weight: 500;
                    font-size: .17rem;
                    color: #1D1F23;
                    margin: .4rem 0rem .17rem;
                }
                .print-right-bot{
                    font-weight: 300;
                    font-size: .13rem;
                    color: #788086;
                    .val{
                        margin-right: .63rem;
                        font-weight: 500;
                        font-size: .13rem;
                        color: #1D1F23;
                    }
                }
            }
        }
    }
    .demand-title{
        margin: .63rem 0rem .13rem;
        :nth-child(1){
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-right: .1rem;
        }
    }
    .demand{
        display: flex;
        align-items: center;
        height: 1.4rem;
        background: #F8FAFB;
        border: .01rem solid #F8FAFB;
        .left{
            display: flex;
            .demand-left{
                img{
                    margin: .13rem .23rem;
                    width: 1.15rem;
                    height: 1.15rem;
                }
            }
            .demand-right{
                .demand-right-top{
                    margin: .23rem 0rem .14rem;
                    font-weight: 300;
                    font-size: .13rem;
                    color: #8594A2;
                    :nth-child(1){
                        font-weight: 500;
                        font-size: .17rem;
                        color: #1D1F23;
                    }
                    :last-child{
                        font-weight: 500;
                        font-size: .13rem;
                        color: #1D1F23;
                    }
                }
                .demand-right-bot{
                    width: 10.65rem;
                    line-height: .15rem;
                    font-weight: 400;
                    font-size: .13rem;
                    color: #1D1F23;
                }
            }
        }
    }
    .express{
        margin: .38rem 0rem .13rem;
        display: flex;
        font-weight: 400;
        font-size: .13rem;
        color: #788086;
        .title{
            margin-right: .1rem;
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
        }
        span{
            color: #09A966;
        }
    }
    .express-content{
        display: flex;
        .item{
            cursor: pointer;
            margin-right: .13rem;
            width: 1.29rem;
            height: .51rem;
            line-height: .51rem;
            text-align: center;
            background: rgba(255,255,255,0.2);
            border: .01rem solid #D7DBE6;
        }
        .active{
            border: .01rem solid #09A966;
        }
    }
    .logistics{
        margin: .38rem 0rem .13rem;
        display: flex;
        font-weight: 400;
        font-size: .13rem;
        color: #788086;
        .title{
            margin-right: .1rem;
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
        }
        span{
            color: #09A966;
        }
    }
    .logistics-content{
        width: 14.5rem;
        height: 2.88rem;
        border: .01rem solid #D7DBE6;
        padding: 0rem .25rem;
        box-sizing: border-box;
        .log-top{
            height: .82rem;
            border-bottom: .01rem solid #D7DBE6;
            display: flex;
            align-items: center;
            img{
                width: .33rem;
                height: .33rem;
                border-radius: 50%;
            }
            span{
                margin-left: .1rem;
            }
            span{
                font-weight: 400;
                font-size: .13rem;
                color: #788086;
            }
            :nth-child(2){
                font-weight: 500;
                font-size: .13rem;
                color: #1D1F23;
            }

        }
        .log-bot{
            padding: .25rem .13rem;
            ::v-deep{
                .el-timeline-item{
                    padding-bottom: .5rem;
                }
            }
        }
    }
    .price-title{
        margin: .38rem 0rem .13rem;
        :nth-child(1){
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-right: .1rem;
        }
    }
    .price-content{
        display: flex;
        flex-direction: column;
        width: 14.5rem;
        // height: 5.19rem;
        border: .01rem solid #D7DBE6;
        .price-title{
            // width: 1158px;
            height: .61rem;
            background: #F8FAFB;
            line-height: .61rem;
            text-align: center;
            display: flex;
            box-sizing: border-box;
            
        }
        .price-item{
            display: flex;
            height: .55rem;
        }
        .price-item,.price-title{
            padding: 0rem .63rem;
            align-items: center;
            // border: 1px solid black;
            .one{
                text-align: left;
                width: 15%;
                
                font-weight: 400;
                font-size: .15rem;
                color: #1D1F23;
                // background: pink;
            }
            .two{
                // text-align: center;
                width: 35%;
                display: flex;
                justify-content: center;
                // background: pink;
                .jia,.jian{
                    width: .3rem;
                    height: .3rem;
                    line-height: .3rem;
                    text-align: center;
                    background: #FFFFFF;
                    border: .01rem solid #C4C6CF;
                }
                .price{
                    margin: 0rem .06rem;
                    width: .49rem;
                    height: .3rem;
                    line-height: .3rem;
                    text-align: center;
                    background: #FFFFFF;
                    border: .01rem solid #C4C6CF;
                }
                .dis{
                    background: #F8FAFB;
                    border: .01rem solid #E6E7ED;
                }
            }
            .three{
                text-align: left;
                width: 25%;
                // background: orange;
                font-weight: 400;
                font-size: .15rem;
                color: #1D1F23;
            }
            .four{
                text-align: center;
                width: 25%;
                font-weight: 600;
                font-size: .15rem;
                color: #EB0A0D;
                .sumP{
                    color: #EB0A0D;
                    font-size: .23rem;
                }
            }
        }
    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        font-weight: 500;
        font-size: .17rem;
        .save{
            margin-right: .25rem;
            width: 1.2rem;
            height: .5rem;
            text-align: center;
            line-height: .5rem;
            background: #FFFFFF;
            border-radius: 0rem 0rem 0rem 0rem;
            border: .01rem solid #09A966;
            color: #09A966;
        }
        .submit{
            width: 1.02rem;
            height: .5rem;
            text-align: center;
            color: #FFFFFF;
            line-height: .5rem;
            background: #09A966;
        }
    }
}
</style>