import { getType } from "../../api/home"
const type = {
    state: {
        demandType:[{"name":"全部"}],
        modelType:[]
    },

    mutations: {
        // saveType(state,data){
        //     state.modelType = data.find(obj => obj.id === 12).node;
        //     state.demandType = data.find(obj => obj.id === 18).node;
        //     console.log(state.demandType,"demandType")
        // }
        getTypeInfo(state){
            var a = []
            var b = []
            getType({
                id:0
            }).then(res=>{
                a = res.data.category.find(obj => obj.id === 12).node;
                b = res.data.category.find(obj => obj.id === 18).node;
                // context.commit('saveType', res.data.category);
            }).catch(err=>{
                console.log(err)
            })
            console.log(a,b);
        },
    },

    action: {
        getTypeInfo:(context)=>{
            getType({
                id:0
            }).then(res=>{
                context.commit('saveType', res.data.category);
            }).catch(err=>{
                console.log(err)
            })
        },
    }
}

export default type
