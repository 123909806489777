import request from '@/utils/request.js';

//获取模型列表
export function getModelList(params) {
    return request({
        url: "api/user.model/index",
        method: 'get',
        params,
    });
}

//添加模型
export function addModel(data) {
    return request({
        url: "api/user.model/add",
        method: 'post',
        data,
    });
}

//修改模型
export function fixModel(data) {
    return request({
        url: "api/user.model/edit",
        method: 'post',
        data,
    });
}

//产权登记
export function addequ(data) {
    return request({
        url: "api/copyright/add",
        method: 'post',
        data,
    });
}

//产权信息
export function quanDel(params) {
    return request({
        url: "api/copyright/find",
        method: 'get',
        params,
    });
}

//删除模型
export function delModel(params) {
    return request({
        url: "api/user.model/delete",
        method: 'get',
        params,
    });
}

//模型详情
export function modelDel(params) {
    return request({
        url: "api/user.model/find",
        method: 'get',
        params,
    });
}