<template>
    <div class="demandTwo">
        <div class="model-title">
            <span>需求名称</span>
        </div>
        <div class="model">
            <div class="left">
                <div class="model-left">
                    <img :src="model.img" alt="">
                </div>
                <div class="model-right">
                    <div class="model-right-top">{{model.name}}</div>
                    <div class="model-right-bot">
                        <span class="title">长宽高：</span><span class="val">{{model.size}}</span>
                        <span class="title">体积：</span><span class="val">{{model.volume}}</span>
                        <span class="title">表面积：</span><span class="val">{{model.area}}</span>
                    </div>
                </div>
            </div>
            <div class="border"></div>
            <div class="right">
                <div class="right-top">
                    <span v-for="(item,index) in model.label" :key="index">{{item}}</span>
                </div>
                <div class="right-bot">
                    <span class="title">模具分类：</span><span class="val">产品设计</span>
                </div>
            </div>
        </div>
        <div class="materials-title">
            <span>选择打印耗材</span>
            <span>根据模型信息，有以下耗材可供选择</span>
        </div>
        <div class="materials">
            <div class="swiper mySwiper">
                <div class="swiper-wrapper">
                    <div v-for="item in materialsList" :key="item.id" :class="materAct.id == item.id ? 'swiper-slide active' : 'swiper-slide'" @click="materAct = item">
                        <div class="img">
                            <img :src="item.image" alt="">
                        </div>
                        <div class="name">{{item.name}}</div>
                        <div class="item-price">
                        <div>耗材费用：</div>
                        <div class="price"><span>￥</span><span>{{item.price}}</span><span>/件</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="swiper-btn-next">
                <img src="../../assets/detail/arrow-right.png" alt="">
            </div>
            <div class="swiper-btn-prev">
                <img src="../../assets/detail/arrow-left.png" alt="">
            </div>
        </div>
        <div class="bottom-btn">
            <div class="save" @click="saveDem">保存模型</div>
            <div class="submit" @click="denAdd">下一步</div>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper";
import "../../styles/swiper/css/swiper-bundle.min.css";
import "../../styles/swiper/js/swiper-bundle.min.js";
import { addDemand } from "../../api/home"
import { addModel , fixModel } from "../../api/layout/model"
    export default {
        data(){
            return{
                model:{
                    img:require("../../assets/detail/mater-one.png"),
                    name:"患者-牙齿模型.stl",
                    size:"24x8x2mm",
                    volume:"384mm²",
                    area:"500mm³",
                    label:["艺术品","毕业设计","3D打印"]
                },
                materialsList:[],
                materAct:{}
            }
        },
        methods:{
            creatSwiper(){
                new Swiper(".mySwiper", {
                    slidesPerView: 5,
                    spaceBetween: 10,
                    loop: true,
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                });
            },
            denAdd(){
                this.$store.commit("setProId",this.materAct)
                this.$emit('addAct')
            }, 
            saveDem(){
                console.log("1");
                if(this.$store.getters.info["status"] == "fix"){
                    console.log("2");
                    this.fixModel()
                }else{
                    this.submit()
                }
            },
            // 修改模型
            fixModel(){
                let params = {
                    id:this.$store.getters.info["demand-id"],
                    name:this.$store.getters.info["model-name"],
                    category_id:this.$store.getters.info["demand-type"],
                    source:this.$store.getters.info["model-source"],
                    label:this.$store.getters.info["demand-label"],
                    file: this.$store.getters.info["demand-img"],
                    image: this.$store.getters.info["demand-img"],
                    size: "24*8*2mm",
                    file_size: "122m",
                    volume:"384mm",
                    area: "500mm"
                }
                console.log(params,"999999999");
                fixModel(params).then(res=>{
                    console.log(res.data.id,"res.data.id");
                    if(res.code == 1 && val == 1){
                        this.$store.commit('closeDemFlag')
                    }else{
                        this.$store.commit("setDem",{title:'demand-id',val:res.data.id})
                        // console.log();
                        this.denAdd()
                    }
                }).catch(err=>{
                    console.log(err);
                })
            },
            // 上传模型
            submit(){
                
                if(this.$store.getters.info["demand-id"]){
                    this.$message({
                        message: '请勿重复添加！',
                        type: 'warning'
                    });
                    return
                }
                
                let params={
                    name:this.$store.getters.info["model-name"],
                    category_id:this.$store.getters.info["demand-type"],
                    source:this.$store.getters.info["model-source"],
                    label:this.$store.getters.info["demand-label"],
                    file: this.$store.getters.info["demand-img"],
                    image: this.$store.getters.info["demand-img"],
                    size: "24*8*2mm",
                    file_size: "122m",
                    volume:"384mm",
                    area: "500mm"
                }
                addModel(params).then(res=>{
                    this.$message({
                        message: `${res.msg}`,
                        type: 'success'
                    });
                    console.log(res.data.id,"res.data.id");
                    this.$store.commit("setDem",{title:'demand-id',val:res.data.id})
                    // this.denAdd()
                    // if(res.code == 1 && val == 1){
                    //     this.$store.commit('closeDemFlag')
                    // }else{
                    //     this.$store.commit("setDem",{title:'demand-id',val:res.data.id})
                    //     // console.log();
                    //     this.denAdd()
                    // }
                }).catch(err=>{
                    console.log(err);
                })
            }, 
        },
        mounted() {
            // 初始化swiper
            this.creatSwiper();

            this.materialsList = this.$store.getters.product

            this.materAct = this.$store.getters.productItem
            if(JSON.stringify(this.materAct) == "{}"){
                this.materAct = this.materialsList[0]
            }

            const modelInfo = this.$store.getters.info

            this.model = {
                img: modelInfo["demand-img"],
                name:modelInfo["demand-name"],
                size:modelInfo["demand-size"],
                type:modelInfo["demand-type"],
                volume:"384mm²",
                area:"500mm³",
            }

            console.log(this.materialsList,"55555555");
        },
    }
</script>

<style lang="scss" scoped>
.demandTwo{
    .model-title{
        margin: .63rem 0rem .13rem;
        :nth-child(1){
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-right: .1rem;
        }
    }
    .model{
        display: flex;
        align-items: center;
        height: 1.4rem;
        background: #F8FAFB;
        border: .01rem solid #F8FAFB;
        .left{
            width: 7.25rem;
            display: flex;
            .model-left{
                img{
                    margin: .13rem .23rem;
                    width: 1.15rem;
                    height: 1.15rem;
                }
            }
            .model-right{
                .model-right-top{
                    font-weight: 500;
                    font-size: .17rem;
                    color: #1D1F23;
                    margin: .4rem 0rem .17rem;
                }
                .model-right-bot{
                    font-weight: 300;
                    font-size: .13rem;
                    color: #788086;
                    .val{
                        margin-right: .13rem;
                    }
                }
            }
        }
        .border{
            width: 0rem;
            height: .69rem;
            border: .01rem solid #D7DBE6;
        }
        .right{
            padding-left: .63rem;
            .right-top{
                display: flex;
                margin-bottom: .17rem;
                span{
                    display: block;
                    font-weight: 400;
                    font-size: .13rem;
                    color: #09A966;
                    padding: .03rem .09rem;
                    background: #EDFBF5;
                    margin-right: .06rem;
                }
            }
            .right-bot{
                .title{
                    font-weight: 300;
                    font-size: .13rem;
                    color: #8594A2;
                }
                .val{
                    font-weight: 500;
                    font-size: .13rem;
                    color: #1D1F23;
                }
            }
        }
    }
    .materials-title{
        margin: .63rem 0rem .13rem;
        :nth-child(1){
            font-weight: 600;
            font-size: .15rem;
            color: #1D1F23;
            margin-right: .1rem;
        }
        :last-child{
            margin-left: .13rem;
            font-weight: 400;
            font-size: .13rem;
            color: #09A966;
        }
    }
    .materials{
        position: relative;
        width: 14.5rem;
        height: 3.66rem;
        padding: .25rem .75rem;
        box-sizing: border-box;
        border: .01rem solid #D7DBE6;
        .swiper{
            .swiper-wrapper{
                .swiper-slide{
                    cursor: pointer;
                    width: 2.5rem;
                    height: 3.16rem;
                    background: #FFFFFF;
                    border-radius: 0rem 0rem 0rem 0rem;
                    border: .01rem solid #F5F5F5;
                    display: flex;
                    flex-direction: column;
                    padding: .13rem;
                    box-sizing: border-box;
                    margin-right: .13rem;
                    .img{
                        width: 2.25rem;
                        height: 2.13rem;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .name{
                        margin: .13rem 0rem .1rem;
                        font-weight: 600;
                        font-size: .17rem;
                        color: #1D1F23;
                    }
                    .item-price{
                        padding-top: .1rem;
                        box-sizing: border-box;
                        border-top: .01rem solid #F5F5F5;
                        display: flex;
                        align-items: center;
                        font-weight: 400;
                        font-size: .13rem;
                        color: #8594A2;
                        .price{
                            :nth-child(1){
                                font-weight: bold;
                                font-size: .15rem;
                                color: #E75763;
                            }
                            :nth-child(2){
                                font-weight: bold;
                                font-size: .2rem;
                                color: #E75763;
                            }
                            :nth-child(3){
                                font-weight: 400;
                                font-size: .1rem;
                                color: #E75763;
                            }
                        }
                    }
                }
                .active{
                    border: .01rem solid #09A966;
                }
            }
        }
        .swiper-btn-next{
            position: absolute;
            z-index: 99;
            top: 1.88rem;
            right: .25rem;
            img{
                width: .25rem;
                height: .25rem;
            }
        }
        .swiper-btn-prev{
            position: absolute;
            z-index: 99;
            top: 1.88rem;
            left: .25rem;
            img{
                width: .25rem;
                height: .25rem;
            }
        }
    }
    .bottom-btn{
        margin: .63rem 0rem .63rem;
        display: flex;
        justify-content: flex-end;
        font-weight: 500;
        font-size: .17rem;
        .save{
            margin-right: .25rem;
            width: 1.2rem;
            height: .5rem;
            text-align: center;
            line-height: .5rem;
            background: #FFFFFF;
            border-radius: 0rem 0rem 0rem 0rem;
            border: .01rem solid #09A966;
            color: #09A966;
        }
        .submit{
            width: 1.02rem;
            height: .5rem;
            text-align: center;
            color: #788086;
            line-height: .5rem;
            background: #EFEFEF;
        }
    }
}
</style>